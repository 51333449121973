import * as React from "react";
import api from "@libs/api";

/* MUI */
import {
	Divider,
	Typography,
	FormGroup,
	Box,
	SxProps,
	CircularProgress,
	Button,
} from "@mui/material";

// icons
import {
	Inbox as InboxIcon,
	Home as HomeIcon,
	AccountBalanceWallet as AccountBalanceWalletIcon,
	Payments as PaymentsIcon,
	SupervisedUserCircleRounded as SupervisedUserCircleRoundedIcon,
} from "@mui/icons-material";

// components
import {
	makeEditElement,
	makeInfoElement,
	NavMenuList,
	createNavMenuItem,
	InfoElement,
} from "@commonComponents/EditControlBasics";
import ItemSelectDialog from "@commonComponents/Dialog/ItemSelectDialog";
import EditorMainWrapper from "@commonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { EnhancedTable } from "@table";

import EstateSelectControl from "@mc/Estates/EstateSelectControl";
import PaymentEditControl from "@mc/Payments/PaymentEditControl";
import OwnerItemToggledBadge from "@mc/Owners/OwnerItemToggledBadge";
import {
	billsTableHeadCells,
	CreateBillCellData,
	CreateFeeCellData,
	CreatePaymentsCellData,
	feesTableHeadCells,
	paymentsTableHeadCells,
} from "@mc/Accounts/AccountEditControl.tableProps";

/* TYPES */
import { AxiosResponse } from "communicate-api/node_modules/axios";
import { RecordState } from "@model/RecordState";
import { CommonItemChangeHandler, EditControlProps } from "@commonComponents/CommonTypes";
import { Payment } from "@model/Payment";
import { Estate } from "@model/Estate";
import { Account } from "@model/Account";
import { Owner } from "@model/Owner";
import { Bill } from "@model/Bill";

import { useSnackbar } from "notistack";
import Context from "@infra/Context";
import { Fee } from "@model/Fee";

enum AccountEditPageType {
	main,
	payments,
	bills,
	fees,
	users,
}

export default function AccountEditControl(props: EditControlProps<Account>) {
	const [selectedPage, setPage] = React.useState<AccountEditPageType>(AccountEditPageType.main);
	const [payments, setPayments] = React.useState<Payment[]>([]);
	const [bills, setBills] = React.useState<Bill[]>([]);
	const [fees, setFees] = React.useState<Fee[]>([]);
	const [estateSelectDialogOpened, setEstateSelectDialogOpened] = React.useState<boolean>(false);
	const [editItem, setEditItem] = React.useState(props.editItem);
	/* OWNERS */
	const [ownersEditMode, setOwnersEditMode] = React.useState(false);
	const [vacantOwners, setVacantOwners] = React.useState<Owner[]>([]);
	const [actualOwners, setActualOwners] = React.useState<Owner[]>([]);
	const [isOwnersLoading, setIsOwnersLoading] = React.useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const context = React.useContext(Context);
	const accountId = props.editItem?.Id ?? 0;

	React.useEffect(() => {
		if (accountId) {
			api.get("api/Payments/account", { accId: accountId }, "json").then((response) => {
				if (response && response.data) setPayments(response.data);
			});
			api.get("api/Bills/account", { accId: accountId }, "json").then((response) => {
				if (response && response.data) setBills(response.data);
			});
			api.get("api/Fees/account", { accId: accountId }, "json").then((response) => {
				if (response && response.data) setFees(response.data);
			});
		}
	}, []);

	React.useEffect(() => {
		if (ownersEditMode) {
			fetchEstateOwners(editItem?.Estate.Id)
				.then((response) => {
					setIsOwnersLoading(false);
					if (response && response.data) setVacantOwners(response.data);
				})
				.catch(() => {
					setIsOwnersLoading(false);
				});
		}
	}, [ownersEditMode, editItem?.Estate?.Id]);

	const fetchEstateOwners = async (
		estateId: number | undefined,
	): Promise<AxiosResponse | null> => {
		if (estateId) {
			return api.get("api/Owners/Estate", { estateId: estateId }, "json");
		}

		return null;
	};

	/** Запускает процесс выбора владения (открывает диалоговое окно выбора). */
	const selectEstateHandler = () => {
		setEstateSelectDialogOpened(true);
	};

	/**
	 * Обработчик выбранного владения для лицевого счета.
	 * @param estates - выбранные владение.
	 */
	const estateSelectedHanler = (account: Account, estates: Estate[]) => {
		try {
			const est = estates[0];

			if (est) {
				setEditItem({ ...account, Estate: est });
				if (editItem) {
					onValueChange(editItem, "Estate", { ...est });
				}
			}
		} catch (exception) {
			console.error(exception);
		}
	};

	/** Обработчик вызова закрытия диалога выбора владения. */
	const estateDialogCloseCallHandler = () => {
		setEstateSelectDialogOpened(false);
	};

	const sxOwnersEditor: SxProps = {
		display: "flex",
		flexDirection: "row",
		alignItems: "end",
		border: ownersEditMode ? "2px solid royalblue" : "none",
		borderRadius: 1,
		padding: ownersEditMode ? 1 : 0,
		flexGrow: 1,
	};

	/** Количество знаков после запятой в десятичных дробях. */
	const decimalFixedPosition = 2;

	const sxOwnersEditorButtonWrapper: SxProps = {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		flexGrow: 1,
	};

	const showMainInfo = React.useCallback(
		(item: Account) => {
			/* Проверка заполненности значений элемента */
			if (!item.Estate) {
				item.Estate = {} as Estate;
			}
			if (!item.Estate.Name) {
				item.Estate.Name = "";
			}
			if (!item.Estate.Square) {
				item.Estate.Square = 0;
			}
			if (!item.Owners) {
				item.Owners = [];
			}
			if (!item.Balance) {
				item.Balance = 0;
			}

			const beginEditOwnersHandler = () => {
				setIsOwnersLoading(true);
				setOwnersEditMode(true);
				setActualOwners([...item.Owners]);
			};

			const applyEstateOwnerChange = () => {
				if (actualOwners.length) {
					item.Owners = actualOwners;
					item.State = RecordState.Changed;
					if (editItem) {
						onValueChange(editItem, "Owners", [...actualOwners]);
					}
					setOwnersEditMode(false);
				} else {
					enqueueSnackbar("Должен быть выбран хотя бы один владелец.", {
						variant: "warning",
					});
				}
			};

			const cancelEstateOwnerChange = (): void => {
				setOwnersEditMode(false);
			};

			const onOwnerItemCheckedChange = (isChecked: boolean, owner: Owner): void => {
				const ownerIndex = actualOwners.findIndex((item) => item.Id === owner.Id);
				if (isChecked && ownerIndex < 0) {
					actualOwners.push(owner);
				} else if (!isChecked && ownerIndex >= 0) {
					actualOwners.splice(ownerIndex, 1);
				}
				setActualOwners([...actualOwners]);
			};

			const isSelectedOwnerChecked = (owner: Owner): boolean => {
				const isSelected = actualOwners.findIndex((item) => item.Id === owner.Id) >= 0;
				const result = !ownersEditMode || isSelected;
				return result;
			};

			/* Отображение формы */
			return (
				<>
					<form autoComplete="off">
						{makeInfoElement(
							"Наименование владения",
							item.Estate.Id && item.Estate.Id > 0 ? (
								<>
									<Typography variant="h6">{item.Estate.Name}</Typography>
									{!item.Id || item.Id <= 0 ? (
										<Button
											onClick={selectEstateHandler}
											color="info"
											variant="outlined"
											size="small"
											sx={{ ml: 1 }}
										>
											изменить
										</Button>
									) : null}
								</>
							) : (
								<Button
									onClick={selectEstateHandler}
									color="info"
									variant="contained"
									size="small"
								>
									Выбрать{" "}
									{context.userInfo.activeHoa?.ItemNameCases?.NominativeSingle?.toLowerCase() ??
										"владение"}
								</Button>
							),
						)}
						{
							<InfoElement
								name="Владельцы"
								value={
									<Box sx={sxOwnersEditor}>
										<FormGroup>
											{item.Owners.map((owner) => (
												<OwnerItemToggledBadge
													key={`Owner_${owner.Id}_${item.Id}`}
													owner={owner}
													checked={isSelectedOwnerChecked(owner)}
													canChange={ownersEditMode}
													checkedChange={onOwnerItemCheckedChange}
													disabled={!ownersEditMode}
												/>
											))}
											{
												// Владельцы хозяйственной единицы, не включенные в Л/с
												ownersEditMode ? (
													isOwnersLoading ? (
														<CircularProgress />
													) : (
														vacantOwners
															.filter(
																(o) =>
																	!item.Owners.map(
																		(o) => o.Id,
																	).includes(o.Id),
															)
															.map((owner) => (
																<OwnerItemToggledBadge
																	key={`Owner_${owner.Id}_${item.Id}`}
																	owner={owner}
																	canChange={true}
																	checkedChange={
																		onOwnerItemCheckedChange
																	}
																/>
															))
													)
												) : null
											}
										</FormGroup>
										<Box sx={sxOwnersEditorButtonWrapper}>
											{ownersEditMode ? (
												<>
													<Button
														onClick={cancelEstateOwnerChange}
														color="inherit"
														variant="contained"
														size="medium"
														sx={{ ml: 1 }}
													>
														Отменить
													</Button>
													<Button
														onClick={applyEstateOwnerChange}
														color="success"
														variant="contained"
														size="medium"
														sx={{ ml: 1 }}
													>
														Применить
													</Button>
												</>
											) : (
												<Button
													onClick={beginEditOwnersHandler}
													color="info"
													variant="outlined"
													size="medium"
													sx={{ ml: 1 }}
												>
													Изменить состав владельцев
												</Button>
											)}
										</Box>
									</Box>
								}
							/>
						}

						<InfoElement
							name="Совокупная площадь"
							value={(() => {
								const share = item.Owners.map((o) => o.Share).reduce(
									(a, b) => a + b,
									0,
								);
								const sharedSquareString = (item.Estate.Square * share).toFixed(
									decimalFixedPosition,
								);
								const wholeShareString =
									item.Estate.Square.toFixed(decimalFixedPosition);

								return share < 1 ? (
									<>
										{" "}
										{sharedSquareString} м<sup>2</sup>&nbsp;из{" "}
										{wholeShareString} м<sup>2</sup>{" "}
									</>
								) : (
									<>
										{" "}
										{wholeShareString} м<sup>2</sup>{" "}
									</>
								);
							})()}
						/>

						{item.Id ? (
							<InfoElement
								name="Баланс"
								value={<Typography variant="h6">{item.Balance} ₽</Typography>}
							/>
						) : (
							makeEditElement(
								"Баланс (входящий остаток)",
								item,
								"Balance",
								onValueChange,
								false,
							)
						)}

						<Divider />

						{makeEditElement("Примечание", item, "Remark", onValueChange, false)}
					</form>

					{/* Диалог выбора владения (участка, квартиры, гаража) */}
					<ItemSelectDialog
						onclose={estateDialogCloseCallHandler}
						onselect={(estates) => estateSelectedHanler(item, estates)}
						open={estateSelectDialogOpened}
						dialogCaption={`Выберите ${
							context.userInfo.activeHoa?.ItemNameCases?.NominativeSingle?.toLowerCase() ??
							"владение"
						}`}
						formControl={EstateSelectControl}
						isFullScreen={false}
						dialogMaxSize={false}
						fullWidth={false}
					/>
				</>
			);
		},
		[editItem, ownersEditMode, vacantOwners, estateSelectDialogOpened],
	);

	const handleSavePayment = (payment: Payment) => {
		payment.TransactionDate = payment.TransactionDate ?? new Date();
		if (editItem) {
			payment.AccountId = editItem.Id;
		}

		api.send("api/Payments", payment, "post").then((response) => {
			if (response && response.data) {
				const savedPayment = response.data as Payment;
				if (editItem) {
					editItem.Balance += payment.Value;
				}

				setPayments([...payments, savedPayment]);
			}
		});
	};

	const handleSaveBill = (item: Bill) => {
		console.log(item);
	};

	const handleSaveFee = (item: Fee) => {
		console.log(item);
	};

	const onValueChange: CommonItemChangeHandler<Account> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
	};

	const handleListItemClick = (_event: React.MouseEvent, page: AccountEditPageType) => {
		setPage(page);
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<AccountEditPageType>>
					{[
						createNavMenuItem<AccountEditPageType>(
							AccountEditPageType.main,
							() => selectedPage === AccountEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),
						createNavMenuItem<AccountEditPageType>(
							AccountEditPageType.fees,
							() => selectedPage === AccountEditPageType.fees,
							() => props.mode === "edit",
							"Взносы",
							PaymentsIcon,
							handleListItemClick,
						),
						createNavMenuItem<AccountEditPageType>(
							AccountEditPageType.bills,
							() => selectedPage === AccountEditPageType.bills,
							() => props.mode === "edit",
							"Начисления",
							InboxIcon,
							handleListItemClick,
						),
						createNavMenuItem<AccountEditPageType>(
							AccountEditPageType.payments,
							() => selectedPage === AccountEditPageType.payments,
							() => props.mode === "edit",
							"Платежи",
							AccountBalanceWalletIcon,
							handleListItemClick,
						),
						createNavMenuItem<AccountEditPageType>(
							AccountEditPageType.users,
							() => selectedPage === AccountEditPageType.users,
							() => props.mode === "edit",
							"Пользователи",
							SupervisedUserCircleRoundedIcon,
							handleListItemClick,
						),
					]}
				</NavMenuList>
			}
		>
			<>
				{selectedPage === AccountEditPageType.payments && (
					<EnhancedTable
						key="payment_list_table"
						rows={payments}
						headCells={paymentsTableHeadCells}
						getCellData={CreatePaymentsCellData}
						multiselect={false}
						denseView={true}
						itemEditControl={PaymentEditControl}
						onDataSave={handleSavePayment}
						dialogHeadConstructor={() => "Внесение платежа"}
						editDialogSizeType="small"
						canAdd
					/>
				)}
				{selectedPage === AccountEditPageType.fees && (
					<EnhancedTable
						key="fee_list_table"
						rows={fees}
						headCells={feesTableHeadCells}
						getCellData={CreateFeeCellData}
						multiselect={false}
						denseView={true}
						editDialogSizeType="medium"
						editable={false}
						canAdd
						canDelete
						onDataSave={handleSaveFee}
					/>
				)}
				{selectedPage === AccountEditPageType.bills && (
					<EnhancedTable
						key="bill_list_table"
						rows={bills}
						headCells={billsTableHeadCells}
						getCellData={CreateBillCellData}
						multiselect={false}
						denseView={true}
						editDialogSizeType="medium"
						editable={false}
						onDataSave={handleSaveBill}
					/>
				)}
				{selectedPage === AccountEditPageType.main && editItem && showMainInfo(editItem)}
			</>
		</EditorMainWrapper>
	);
}
