import { AxiosRequestConfig, AxiosResponse } from "communicate-api/node_modules/axios";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import userInfrastructure from "./user";
import personInfrastructure from "./person";
import accountInfrastructure from "./account";
import estateInfrastructure from "./estate";
import organizationInfrastructure from "./organization";
import automationInfrastructure from "./automation";
import hoaInfrastructure from "./hoa";
import Communicate from "communicate-api";

export const apiClient = {
	...userInfrastructure,
	...personInfrastructure,
	...accountInfrastructure,
	...estateInfrastructure,
	...organizationInfrastructure,
	...automationInfrastructure,
	...hoaInfrastructure,
};

export type InteractiveApiProps<T> = {
	/**
	 * Событие `Действия завершены` вызывается при завершении операции с любым исходом. В независимости от результата.
	 * Факт совершения события сообщает, что более никакие операции в методе осуществляться не будут.
	 */
	onFinish?: () => void;
	/**
	 * Событие `Данные загружены` вызывается при успешной загрузке данных из API и
	 * при отсутствии возникших ошибок.
	 */
	onDataLoaded: (data: T) => void;
	/**
	 * Событие `Возникла ошибка` вызывается только в случае возникновения ошибки при осуществлении операции.
	 * @param reason Содержание ошибки
	 */
	onErrorRaised?: (reason: any) => void;
	/**
	 * Делегат `Отображение информации` предназначен для получения доступа к контролу всплывающих уведомлений.
	 */
	onShowInfo?: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
};

export const processPromiseResult = <T>(
	promiseAction: () => Promise<T>,
	props: InteractiveApiProps<T>,
) => {
	promiseAction()
		.then((dataObject) => {
			if (dataObject) props.onDataLoaded(dataObject);
			props.onFinish?.();
		})
		.catch((reason) => {
			props.onShowInfo?.(`Произошла ошибка при загрузке данных. ${reason}`, {
				variant: "error",
			});
			props.onFinish?.();
		});
};

const { session } = new Communicate({
	devUrl: "http://localhost:8083/", // 55875 для запуска через IIS
	prodUrl: "https://ojigovo.ru/",
});

export class Client {
	static get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
		return session.get<T, AxiosResponse<T>>(url, config);
	}

	static post<T, D>(
		url: string,
		data: D,
		config?: AxiosRequestConfig,
	): Promise<AxiosResponse<T>> {
		return session.post<T, AxiosResponse<T>>(url, data, config);
	}
}
