import * as React from "react";
import { Box, Link, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { EnhancedTable } from "@table";
import { CellData } from "@table/Interfaces/CellData";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { Person } from "@model/Person";
import { getDateTextRepresentation } from "@commonComponents/EditControlBasics";
import { useSnackbar } from "notistack";
import PersonEditControl from "./PersonEditControl";
import Context, { UserContext } from "@infra/Context";
import { apiClient } from "@api";
import { useNavigate } from "react-router-dom";
import { AccessMode } from "@model/AccessMode";
import { sxSectionContent } from "@commonComponents/layoutsSx";

function EditDialogHead(item: Person | null) {
	if (item) return `${item.Fullname}`;
	return "Новая персона";
}

function CreateCellData(item: Person): CellData[] {
	return [
		{ align: "left", nodeValue: item.Fullname },
		{ align: "left", nodeValue: getDateTextRepresentation(item.Birthdate) },
		{ align: "left", nodeValue: item.TaxCode },
		{ align: "left", nodeValue: item.InsuranceCode },
		{ align: "left", nodeValue: item.Address },
		{ align: "left", nodeValue: <Box sx={{ whiteSpace: "nowrap" }}>{item.Phone}</Box> },
		{
			align: "left",
			nodeValue: (
				<Link
					href={`mailto:${item.Email}`}
					underline="hover"
					onClick={(ev: React.MouseEvent<HTMLAnchorElement>) => ev.stopPropagation()}
				>
					{item.Email}
				</Link>
			),
		},
		{ align: "left", nodeValue: item.Remark },
	];
}

export function PersonsListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Person[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const context = React.useContext(Context) as UserContext;

	const refreshPersonsList = () => {
		setloading(true);
		apiClient.fetchHoaPersons(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	};

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
		}
		refreshPersonsList();
	}, []);

	const handleSavePerson = (newitem: Person) => {
		const hoaId = context.userInfo.activeHoa?.Id;

		if (hoaId) {
			apiClient.savePerson(newitem, hoaId, {
				onDataLoaded: refreshPersonsList,
				onFinish: () => null,
				onShowInfo: enqueueSnackbar,
			});
		} else {
			enqueueSnackbar(
				`Произошла ошибка при сохранении данных. Сохранение производится не в контексте хозяйства.`,
				{ variant: "error" },
			);
		}
	};

	const headCells: TableHeadCell<Person>[] = [
		{
			id: "Fullname",
			numeric: false,
			disablePadding: false,
			label: "ФИО",
			canSort: true,
		},
		{
			id: "Birthdate",
			numeric: false,
			disablePadding: false,
			label: "Дата рождения",
			canSort: true,
		},
		{
			id: "TaxCode",
			numeric: false,
			disablePadding: false,
			label: "ИНН",
			canSort: true,
		},
		{
			id: "InsuranceCode",
			numeric: false,
			disablePadding: false,
			label: "СНИЛС",
			canSort: true,
		},
		{
			id: "Address",
			numeric: false,
			disablePadding: false,
			label: "Адрес",
			canSort: true,
		},
		{
			id: "Phone",
			numeric: false,
			disablePadding: false,
			label: "Телефон",
			canSort: true,
		},
		{
			id: "Email",
			numeric: false,
			disablePadding: false,
			label: "Email",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
	];

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			rows={rowCollection}
			headCells={headCells}
			getCellData={CreateCellData}
			dialogHeadConstructor={EditDialogHead}
			multiselect={false}
			itemEditControl={PersonEditControl}
			editable
			editDialogSizeType="large"
			onDataSave={handleSavePerson}
			editDialogFullWidth
			canAdd
			canDelete
			denseView={false}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				Персоны
			</Typography>
			<p>Редактирование справочника физических лиц</p>
			{contents}
		</Box>
	);
}
