import { PureComponent, createContext } from "react";
import { node } from "prop-types";
import { storeModeState } from "@libs/utils/local-storage-utils";

import { User } from "@model/User";
import { UserRoleItem } from "@model/UserRoleItem";
import { AccessMode } from "@model/AccessMode";
import { Hoa } from "@model/Hoa";

export type UserWithRoles = User & {
	roles: UserRoleItem[];
	accessMode: AccessMode;
	activeHoa: Hoa | null;
};
export type UserContext = {
	setState: (state: UserWithRoles, callback?: () => void) => void;
	userInfo: UserWithRoles;
};

/**
 * Глобальный контекст.
 * @type {React.Context<{}>}
 */
const Context = createContext<UserContext>({} as UserContext);
Context.displayName = "GlobalContext";

/**
 * Провайдер для глобального контекста.
 *
 * @param children {React.ReactNode} - Дочерние компоненты, которые необходимо обернуть в контекст;
 *
 * @returns {JSX.Element}
 * @constructor
 */
export class ContextProvider extends PureComponent<any, UserWithRoles> {
	static propTypes = {
		children: node.isRequired,
	};

	constructor(props: any) {
		super(props);

		this.state = {} as UserWithRoles;
	}

	/**
	 * Метод для изменения состояния контекста.
	 * Аналогичен методу setState.
	 *
	 * @param newState {object | function} - Новое состояние;
	 * @param callback {function} - Callback, который будет вызван после обновления состояния;
	 */
	updateContext = (newState: UserWithRoles, callback?: () => void) => {
		this.setState(newState, callback);
		storeModeState(newState.activeHoa, newState.accessMode);
	};

	render() {
		return (
			<Context.Provider
				value={{
					setState: this.updateContext,
					userInfo: this.state,
				}}
			>
				{this.props.children}
			</Context.Provider>
		);
	}
}

export const { Consumer } = Context;
export default Context;
