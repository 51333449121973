import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import PlayIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import { EnhancedTable } from "@table";
import { CellData } from "@table/Interfaces/CellData";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { AutomationExtended as Automation } from "@model/AutomationExtended";
import { useSnackbar } from "notistack";
import AutomationEditControl from "./AutomationEditControl";
import Context, { UserContext } from "@infra/Context";
import { apiClient } from "@api";
import { useNavigate } from "react-router-dom";
import { AccessMode } from "@model/AccessMode";
import { sxSectionContent } from "@commonComponents/layoutsSx";
import { AutomationBasic } from "@model/AutomationBasic";
import styles from "./automations.module.css";

export function AutomationListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Automation[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const context = React.useContext(Context) as UserContext;
	const [activatedAutomations, setActivatedAutomations] = React.useState<number[]>([]);

	function EditDialogHead(item: Automation | null) {
		if (item) return `${item.Name}`;
		return "Новый объект автоматизации";
	}

	const OnAutomationExecuteButtonClick = (
		ev: React.MouseEvent<Element, MouseEvent>,
		item: Automation,
	) => {
		ev.stopPropagation();
		activateAutomation(item as unknown as AutomationBasic);
	};

	const activateAutomation = (automation: AutomationBasic) => {
		if (!activatedAutomations.includes(automation.Id)) {
			setActivatedAutomations((data) => [...data, automation.Id]);
			apiClient.activateAutomation(automation, {
				onDataLoaded: (data) => {
					console.log(data);
					enqueueSnackbar(automation.SuccessMessage, { variant: "success" });
				},
				onShowInfo: enqueueSnackbar,
				onFinish: () =>
					setActivatedAutomations((data) => data.filter((item) => item != automation.Id)),
			});
		}
	};

	function getCommandCell(automation: Automation) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<span>{automation.Command}</span>
				<Button
					variant="contained"
					sx={{
						borderRadius: "25px",
						bgcolor: "royalblue",
					}}
					startIcon={
						activatedAutomations.includes(automation.Id) ? (
							<ReplayIcon className={styles.rotate} />
						) : (
							<PlayIcon />
						)
					}
					onClick={(ev) => OnAutomationExecuteButtonClick(ev, automation)}
					disabled={activatedAutomations.includes(automation.Id)}
				>
					{activatedAutomations.includes(automation.Id) ? "Выполняется" : "Выполнить"}
				</Button>
			</Box>
		);
	}

	function CreateCellData(item: Automation): CellData[] {
		return [
			{ align: "left", nodeValue: item.Name },
			{ align: "left", nodeValue: item.Remark },
			// { align: "left", nodeValue: getDateTextRepresentation(item.CreateDate) },
			{ align: "left", nodeValue: getCommandCell(item) },
		];
	}
	const refreshAutomationsList = () => {
		setloading(true);
		apiClient.fetchHoaAutomations(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	};

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
		}
		refreshAutomationsList();
	}, []);

	const handleSaveAutomation = (newitem: Automation) => {
		const hoaId = context.userInfo.activeHoa?.Id;

		if (hoaId) {
			apiClient.saveAutomation(newitem, hoaId, {
				onDataLoaded: refreshAutomationsList,
				onFinish: () => null,
				onShowInfo: enqueueSnackbar,
			});
		} else {
			enqueueSnackbar(
				`Произошла ошибка при сохранении данных. Сохранение производится не в контексте хозяйства.`,
				{ variant: "error" },
			);
		}
	};

	const headCells: TableHeadCell<Automation>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Наименование",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
		{
			id: "Command",
			numeric: false,
			disablePadding: false,
			label: "Команда",
			canSort: true,
		},
	];

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			sx={{ flexGrow: 1 }}
			rows={rowCollection}
			headCells={headCells}
			getCellData={CreateCellData}
			dialogHeadConstructor={EditDialogHead}
			multiselect={false}
			itemEditControl={AutomationEditControl}
			editable
			editDialogSizeType="large"
			onDataSave={handleSaveAutomation}
			editDialogFullWidth
			canAdd
			canDelete
			denseView={false}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				Объекты автоматизации
			</Typography>
			<p>Редактирование справочника объектов автоматизации и управление ими</p>
			{contents}
		</Box>
	);
}
