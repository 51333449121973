import { ButtonInfo } from "./Interfaces/ButtonInfo";
import { EnhancedTableToolbarProps } from "./Interfaces/ToolbarProps";
import { ButtonType } from "./Types";
import { classesCommands } from "./styles";

// MUI
import { alpha } from "@mui/material/styles";
import { TextField, Typography, Toolbar, Box, IconButton, Tooltip } from "@mui/material";

// icons
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const { numSelected } = props;

	const buttonInfos: Record<string, ButtonInfo> = {
		delete: { title: "Удалить", icon: <DeleteIcon />, ariaLabel: "delete item" },
		filter: { title: "Все фильтры", icon: <FilterListIcon />, ariaLabel: "filter list" },
		add: { title: "Добавить", icon: <AddIcon />, ariaLabel: "add item" },
		edit: { title: "Редактировать", icon: <EditIcon />, ariaLabel: "edit item" },
	};

	/**
	 * Make Command Button - функция возвращает кнопку запрашиваемого типа
	 */
	const mcb = (command: ButtonType, clickHandler: (event: React.MouseEvent) => void) => {
		const bi = buttonInfos[command];

		return bi ? (
			<Tooltip title={bi.title}>
				<IconButton aria-label={bi.ariaLabel} onClick={clickHandler}>
					{bi.icon}
				</IconButton>
			</Tooltip>
		) : null;
	};

	const filterChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
		event,
	) => props.onCallDataFilter?.(event.target.value);

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
				flexShrink: 0,
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: "1 1 100%" }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} выбрано
				</Typography>
			) : (
				<TextField
					margin="normal"
					id={`table_find_field`}
					label={"Поиск"}
					defaultValue=""
					variant="standard"
					onChange={filterChangeHandler}
					type={"text"}
				/>
			)}
			{numSelected > 0 ? (
				<Box sx={classesCommands}>
					{props.showDeleteButton &&
						mcb("delete", () => {
							props.onCommandClick("delete");
						})}
					{props.showEditButton &&
						mcb("edit", () => {
							props.onCommandClick("edit");
						})}
				</Box>
			) : (
				<Box sx={classesCommands}>
					{props.showAddButton &&
						mcb("add", () => {
							props.onCommandClick("add");
						})}
				</Box>
			)}
		</Toolbar>
	);
};
