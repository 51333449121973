import { Estate } from "@model/Estate";
import { InteractiveApiProps, processPromiseResult } from "..";

const methods = {
	fetchHoaEstates(hoaId: number | undefined, props: InteractiveApiProps<Estate[]>) {
		import("./get-hoa-estate-list").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
	saveEstate(estate: Estate, hoaId: number | undefined, props: InteractiveApiProps<Estate>) {
		import("./save-estate").then((module) => {
			processPromiseResult(() => module.default(estate, hoaId ?? -1), props);
		});
	},
};

export default methods;
