import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Context from "@infra/Context";
import { apiClient } from "@api";

import LinearProgress from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";

import { EnhancedTable } from "@table";
import { CellData, HeadCell } from "@table/Interfaces/";
import EstateEditControl from "@mc/Estates/EstateEditControl";
import { Estate } from "@model/Estate";
import { AccessMode } from "@model/AccessMode";
import { sxSectionContent } from "@commonComponents/layoutsSx";

export function EstatesListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Estate[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const context = React.useContext(Context);
	const navigate = useNavigate();

	const refreshEstatesList = () => {
		setloading(true);
		apiClient.fetchHoaEstates(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	};

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
			return;
		}
		refreshEstatesList();
	}, []);

	const computeEditDialogHead = React.useCallback((item: Estate | null) => {
		if (item)
			return `${context.userInfo.activeHoa?.ItemNameCases?.NominativeSingle ?? "Владение"} №${
				item.LocalNumber
			} (${item.Name})`;
		return `Регистрация ${
			context.userInfo.activeHoa?.ItemNameCases?.GenitiveSingle.toLowerCase() ?? "владения"
		}`;
	}, []);

	const createCellData = React.useCallback((item: Estate): CellData[] => {
		return [
			{ align: "left", nodeValue: item.Name },
			{ align: "right", nodeValue: item.Square },
			{
				align: "left",
				nodeValue: (
					<a href={"https://rosreestronline.ru/order/?cadNum=" + item.KadNumber}>
						{item.KadNumber}
					</a>
				),
			},
			{ align: "left", nodeValue: item.Remark },
		];
	}, []);

	const handleSaveEstate = (newitem: Estate) => {
		apiClient.saveEstate(newitem, context.userInfo.activeHoa?.Id, {
			onDataLoaded: refreshEstatesList,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	};

	const headCells: HeadCell<Estate>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Владение",
			canSort: true,
			sortingValuesSelector: (value: Estate) => value.LocalNumber,
		},
		{
			id: "Square",
			numeric: true,
			disablePadding: false,
			label: "Площадь м2",
			canSort: true,
		},
		{
			id: "KadNumber",
			numeric: true,
			disablePadding: false,
			label: "Кадастровый номер",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
	];

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			rows={rowCollection}
			headCells={headCells}
			getCellData={createCellData}
			dialogHeadConstructor={computeEditDialogHead}
			multiselect={false}
			itemEditControl={EstateEditControl}
			editable
			editDialogSizeType="large"
			onDataSave={handleSaveEstate}
			editDialogFullWidth
			canAdd
			denseView={false}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				{context.userInfo.activeHoa?.CollectiveItemName || "Владения"}
			</Typography>
			<p>
				Управление обектами, входящими в состав{" "}
				{context.userInfo.activeHoa?.Name || "хозяйства"}
			</p>
			{contents}
		</Box>
	);
}
