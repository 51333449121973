;
export enum PeriodicityType { 
	Once = 0,
	Daily = 1,
	Weekly = 2,
	Monthly = 4,
	Quarterly = 8,
	HalfYearly = 16,
	Yearly = 32
};
;