import * as React from "react";
import { number, objectOf, oneOfType, string } from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";

import classes from "./styles.module.scss";

/**
 * Компонент для отображения загрузки.
 *
 * @param [style] {React.CSSProperties} - Inline стили компонента;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Loading = (a: { style: React.CSSProperties }) => (
	<div className={classes.loading} style={a.style}>
		<CircularProgress />
	</div>
);

Loading.propTypes = {
	className: string,
	style: objectOf(oneOfType([string, number])),
};

Loading.defaultProps = {
	className: undefined,
	style: undefined,
};

export default Loading;
