import * as React from "react";
import { LinearProgress } from "@mui/material";
import EditorMainWrapper from "@commonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { SelectControlProps } from "@commonComponents/CommonTypes";
import { EnhancedTable } from "@table";
import { CellData } from "@table/Interfaces/CellData";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { Estate } from "@model/Estate";
import { useSnackbar } from "notistack";
import Context, { UserContext } from "@infra/Context";
import { apiClient } from "@api";

export default function EstateSelectControl(props: SelectControlProps<Estate>) {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Estate[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const context = React.useContext(Context) as UserContext;

	React.useEffect(() => {
		apiClient.fetchHoaEstates(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	}, []);

	function CreateCellData(item: Estate): CellData[] {
		return [
			{ align: "left", nodeValue: item.Name },
			{ align: "right", nodeValue: item.Square },
			{
				align: "left",
				nodeValue: (
					<a href={"https://rosreestronline.ru/order/?cadNum=" + item.KadNumber}>
						{item.KadNumber}
					</a>
				),
			},
			{ align: "left", nodeValue: item.Remark },
		];
	}

	const headCells: TableHeadCell<Estate>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Владение",
			canSort: true,
			sortingValuesSelector: (value: Estate) => value.LocalNumber,
		},
		{
			id: "Square",
			numeric: true,
			disablePadding: false,
			label: "Площадь м2",
			canSort: true,
		},
		{
			id: "KadNumber",
			numeric: true,
			disablePadding: false,
			label: "Кадастровый номер",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
	];

	const singleItemSelectHandler = (estate: Estate) => {
		props.onItemSelect?.(estate);
		props.onSelectionDoneCall?.();
	};

	const selectedCountChangeHandler = (estates: Estate[]) => {
		props.onItemsSelect?.(estates);
	};

	return (
		<EditorMainWrapper>
			{loading ? (
				<div>
					<em>Загрузка хозяйственных единиц...</em>
					<LinearProgress color="primary" />
				</div>
			) : (
				<EnhancedTable
					rows={rowCollection}
					headCells={headCells}
					getCellData={CreateCellData}
					multiselect={false}
					editDialogSizeType="large"
					onDataSave={() => null}
					editDialogFullWidth
					denseView={false}
					canSelect
					onSelectionForce={singleItemSelectHandler}
					onSelectedCountChange={selectedCountChangeHandler}
				/>
			)}
		</EditorMainWrapper>
	);
}
