import React, { FC } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import InputMask from "react-input-mask";

/** Поле ввода Mui с маской. */
const MaskedTextField: FC<TextFieldProps & { mask: string | (string | RegExp)[] }> = (props) => {
	const {
		// Свойства, транслируемые в поле ввода напрямую.
		onChange,
		onPaste,
		onMouseDown,
		onFocus,
		onBlur,
		value,
		disabled,
		mask,
		// Props передаваемые напрямую в TextField.
		...otherProps
	} = props;

	return (
		// На данный момент бибиотека не поддерживает 18 Реакт. Поэтому игнорим ошибки.
		// @ts-ignore
		<InputMask
			mask={mask}
			onChange={onChange}
			onPaste={onPaste}
			onMouseDown={onMouseDown}
			onFocus={onFocus}
			onBlur={onBlur}
			value={value as any}
			disabled={disabled}
		>
			{/* @ts-ignore */}
			{(maskInputProps: any) => (
				<TextField
					{...maskInputProps}
					{...otherProps}
					disabled={disabled} // InputMask косячит при передаче disabled в Mui.
				/>
			)}
		</InputMask>
	);
};

export default MaskedTextField;
