import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { EnhancedTable } from "@table";
import PersonIcon from "@mui/icons-material/Person";
import { User } from "@model/User";
import api from "@libs/api";
import { useSnackbar } from "notistack";
import { CellData } from "@table/Interfaces/CellData";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AccessMode } from "@model/AccessMode";
import Context, { UserContext } from "@infra/Context";
import { apiClient } from "@api";
import { sxSectionContent } from "@commonComponents/layoutsSx";

function EditDialogHead(item: User | null) {
	if (item) return `Пользователь ${item.Id}`;
	return "Создание нового пользователя";
}

function CreateCellData(item: User): CellData[] {
	const date: Date = new Date(item.LastLoginDate);
	return [
		{ align: "left", nodeValue: <PersonIcon /> },
		{ align: "left", nodeValue: item.Login },
		{ align: "left", nodeValue: item.Email },
		{ align: "left", nodeValue: item.Name ? item.Name.Fullname : "" },
		{ align: "left", nodeValue: item.Phone },
		{
			align: "right",
			nodeValue: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
		},
	];
}

export function UsersListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<User[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const context = React.useContext(Context) as UserContext;

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
			return;
		}

		apiClient.fetchHoaUsers(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	}, []);

	const handleSaveUser = (item: User) => {
		api.send("api/Users", item, "post");
	};

	const headCells: TableHeadCell<User>[] = [
		{
			id: "Id",
			numeric: false,
			disablePadding: true,
			label: "",
			canSort: false,
			size: "small",
		},
		{
			id: "Login",
			numeric: false,
			disablePadding: false,
			label: "Логин",
			canSort: true,
		},
		{
			id: "Email",
			numeric: false,
			disablePadding: false,
			label: "E-mail",
			canSort: true,
		},
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "ФИО",
			canSort: true,
			sortingValuesSelector: (obj) => obj.Name.Fullname,
		},
		{
			id: "Phone",
			numeric: false,
			disablePadding: false,
			label: "Телефон",
			canSort: true,
		},
		{
			id: "LastLoginDate",
			numeric: true,
			disablePadding: false,
			label: "Дата последней авторизации",
			canSort: true,
		},
	];

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			rows={rowCollection}
			headCells={headCells}
			getCellData={CreateCellData}
			dialogHeadConstructor={EditDialogHead}
			multiselect={false}
			editable
			editDialogSizeType="large"
			onDataSave={handleSaveUser}
			editDialogFullWidth
			canAdd
			denseView={false}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				Пользователи системы
			</Typography>
			<p>Управление пользователями</p>
			{contents}
		</Box>
	);
}
