import * as React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// components
import { FormControl, Button, DialogActions, SxProps, Theme } from "@mui/material";

import { makeEditElement, makeInfoElement } from "../../CommonComponents/EditControlBasics";
import { User } from "@model/User";
import { UserName } from "@model/UserName";
import Context, { UserContext } from "../../Infrastructure/Context";
import Loading from "../../CommonComponents/Loading";
import api from "../../../lib/api";
import { useSnackbar } from "notistack";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { apiClient } from "../../../api-client";

export default function UserProfileEditForm() {
	const [editItem, setEditItem] = React.useState<User>({} as User);
	const [loading, setloading] = React.useState(true);
	const context = React.useContext(Context) as UserContext;
	const { enqueueSnackbar } = useSnackbar();

	const valueChangeHandler = function <T extends User | UserName>(
		changedItem: T,
		changedFieldName: keyof T,
		newValue: any,
	) {
		changedItem[changedFieldName] = newValue;

		if (changedItem.TypeName === "UserName") {
			editItem.Name = changedItem;
		}
	};

	React.useEffect(() => {
		apiClient.fetchUserById(context.userInfo.Id).then((user) => {
			setEditItem(user);
			setloading(false);
		});
	}, [context.userInfo.Id]);

	function showMainInfo(
		item: User,
		onValueChange: <T extends User | UserName>(
			changedItem: T,
			changedFieldName: keyof T,
			newValue: any,
		) => void,
	) {
		const onSave = () => {
			const requestData = { UserInfo: item, Password: "" };
			setloading(true);

			api.send("api/Users/Save", requestData, "post").then((response) => {
				if (response && response.data) {
					// alert((response.data as { userId: number }).userId);

					enqueueSnackbar("Данные пользователя успешно сохранены.", {
						variant: "success",
					});
				}
				setloading(false);
			});
		};

		const sxRoot: SxProps<Theme> = (theme) => ({
			pl: theme.spacing(2),
			pr: theme.spacing(1),
			display: "flex",
			alignItems: "baseline",
		});

		return (
			<FormControl sx={sxRoot}>
				<Typography variant="h6" color="primary">
					Мой профиль
				</Typography>
				<br />
				{makeInfoElement("Логин", item.Login)}
				{makeEditElement("Имя", item.Name, "Firstname", onValueChange, true)}
				{makeEditElement("Отчество", item.Name, "Patronymic", onValueChange, true)}
				{makeEditElement("Фамилия", item.Name, "Surname", onValueChange, true)}
				{makeEditElement("Телефон", item, "Phone", onValueChange, false)}
				{makeEditElement("Эл.почта", item, "Email", onValueChange, false)}

				<Divider />

				<DialogActions>
					<Button onClick={onSave} color="secondary" variant="contained">
						Сохранить
					</Button>
				</DialogActions>
			</FormControl>
		);
	}

	return loading ? (
		<Loading />
	) : (
		<React.Suspense fallback={<Loading />}>
			<EditorMainWrapper>
				{!loading && editItem ? showMainInfo(editItem, valueChangeHandler) : null}
			</EditorMainWrapper>
		</React.Suspense>
	);
}
