import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Theme, SxProps } from "@mui/material/styles";
import {
	Box,
	Button,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
	darken,
} from "@mui/material";
import * as MUIcon from "@mui/icons-material";
import EstateIcon from "@mui/icons-material/Cottage";
import PersonsIcon from "@mui/icons-material/Groups2";
import AccountIcon from "@mui/icons-material/AccountBalanceWallet";
import AutomationIcon from "@mui/icons-material/PrecisionManufacturing";
import Context, { UserContext } from "@infra/Context/";
import { Account } from "@model/Account";
import Loading from "@commonComponents/Loading";
import { useSnackbar } from "notistack";
import AccountBadgeBar from "@mc/Accounts/AccountBadgeBar";
import { apiClient } from "@api";
import { AccessMode } from "@model/AccessMode";
import { HoaDashboardData } from "@model/HoaDashboardData";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./home.module.css";
import { AutomationExtended } from "@model/AutomationExtended";
import { AutomationBasic } from "@model/AutomationBasic";

interface IconProps {
	icon: keyof typeof MUIcon;
	sx?: SxProps<Theme>;
}

const IconComp: React.FC<IconProps> = ({ icon, sx }) => {
	const Icon = MUIcon[icon];
	return <Icon sx={sx} />;
};

export function Home() {
	const [loading, setloading] = React.useState(true);
	const [accountCollection, setAccountCollection] = React.useState<Account[]>([]);
	const [dashboardData, setDashboardData] = React.useState<HoaDashboardData | undefined>(
		undefined,
	);
	const [hoaAutomations, setHoaAutomations] = React.useState<AutomationExtended[]>([]);
	const [userAutomations, setUserAutomations] = React.useState<AutomationExtended[]>([]);
	const [activatedAutomations, setActivatedAutomations] = React.useState<number[]>([]);
	const context = React.useContext(Context) as UserContext;
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ManagerMode) {
			apiClient.getHoaDashboard(context.userInfo.activeHoa?.Id, {
				onDataLoaded: (data) => setDashboardData(data),
			});

			apiClient.fetchHoaAutomations(context.userInfo.activeHoa?.Id, {
				onDataLoaded: (data) => setHoaAutomations(data.reverse()),
			});
		}

		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			apiClient.fetchUserAccounts({
				onDataLoaded: setAccountCollection,
				onFinish: () => setloading(false),
				onShowInfo: enqueueSnackbar,
			});

			apiClient.fetchUserAutomations({
				onDataLoaded: (data) => setUserAutomations(data.reverse()),
			});
		}
	}, [context.userInfo.accessMode]);

	const sxRoot: SxProps<Theme> = (theme) => ({
		p: theme.spacing(2),
		bgcolor: theme.palette.background.paper,
	});

	const sxEstatesWrapper: SxProps<Theme> = (theme) => ({
		"pr": theme.spacing(1),
		"pl": theme.spacing(0),
		"display": "flex",
		"flexDirection": "row",
		"justifyContent": "flex-start",
		"overflowX": "auto",
		"overflowY": "clip",
		"paddingTop": "10px",
		"flexBasis": "160px",
		"@media (min-width: 450px)": { flexWrap: "wrap" },
	});

	const sxWidgetsWrapper: SxProps<Theme> = () => ({
		display: "flex",
		flexDirection: "column",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignContent: "start",
		flexGrow: "1",
		overflowY: "auto",
	});

	const sxWidgets: SxProps<Theme> = () => ({
		border: "2px solid",
		borderRadius: 3,
		mb: 2,
		mr: 1,
		display: "none",
	});

	const userNamePatronimic =
		`${context.userInfo?.Name.Firstname} ${context.userInfo?.Name.Patronymic}`.trim();

	const getHelloText = () => {
		const currTime = new Date();
		const currHour = currTime.getHours();
		if (currHour >= 0 && currHour < 6) {
			return "Доброй ночи";
		}
		if (currHour >= 6 && currHour < 12) {
			return "Доброе утро";
		}
		if (currHour >= 12 && currHour < 18) {
			return "Добрый день";
		}
		if (currHour >= 18 && currHour <= 23) {
			return "Добрый вечер";
		}
	};

	const activateAutomation = (automation: AutomationBasic) => {
		if (!activatedAutomations.includes(automation.Id)) {
			setActivatedAutomations((data) => [...data, automation.Id]);
			apiClient.activateAutomation(automation, {
				onDataLoaded: (data) => {
					console.log(data);
					enqueueSnackbar(automation.SuccessMessage, { variant: "success" });
				},
				onShowInfo: enqueueSnackbar,
				onFinish: () =>
					setActivatedAutomations((data) => data.filter((item) => item != automation.Id)),
			});
		}
	};

	const indicatorBox = (
		title: string,
		icon: JSX.Element,
		color: string,
		link: string,
		data: { title: string; indicator: string | number | undefined }[],
	) => (
		<Link to={link} underline="none" component={RouterLink}>
			<Box className={styles.indicatorBox} sx={{ color: color }}>
				<Box className={styles.indicatorHorizontalWrapper}>
					{icon}
					<Typography className={styles.indicatorTitle}>{title}</Typography>
				</Box>
				<Box className={styles.indicatorHorizontalWrapper}>
					{data.map((item) => (
						<Box className={styles.indicatorVerticalWrapper} key={item.title}>
							<Typography className={styles.indicatorName}> {item.title}</Typography>
							<Typography className={styles.indicator}>
								{item.indicator || item.indicator === 0 ? (
									String(item.indicator)
								) : (
									<CircularProgress
										sx={{
											width: "24px !important",
											height: "24px !important",
											color,
										}}
									/>
								)}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</Link>
	);

	return (
		<Box sx={sxRoot} className={styles.homePageRoot}>
			<Typography variant="h4" component="div">
				<span>{getHelloText()}, </span>
				<span className={styles["text-nowrap"]}>{userNamePatronimic}!</span>
			</Typography>

			{context.userInfo.accessMode === AccessMode.ClientMode && (
				<>
					<Typography variant="h6" component="div">
						Ваши лицевые счета
					</Typography>

					<Box id="estates-wrapper" sx={sxEstatesWrapper}>
						{loading ? (
							<Loading />
						) : (
							accountCollection.map((account, index) => (
								<AccountBadgeBar account={account} key={index} />
							))
						)}
						{/* <AccountAddBadgeBar /> */}
					</Box>

					<Box id="widgets-wrapper" sx={sxWidgetsWrapper}>
						{/* Последние платежи */}

						<Box sx={sxWidgets}>
							<Box
								sx={{
									borderBottom: "2px solid",
									padding: "5px",
									backgroundColor: "royalblue",
									borderRadius: "10px 10px 0 0",
								}}
							>
								<Typography variant="body1" color="white" fontWeight="bold">
									{" "}
									Последние платежи
								</Typography>
							</Box>
							<Box sx={{ padding: 2 }}>
								<List>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Через Банк  500₽" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Мягких Н.И.  1300₽" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Мягких Н.И.  1300₽" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Мягких Н.И.  1300₽" />
										</ListItemButton>
									</ListItem>
								</List>
							</Box>
						</Box>

						{/* Последние Обращения */}
						<Box sx={sxWidgets}>
							<Box
								sx={{
									borderBottom: "2px solid",
									padding: "5px",
									backgroundColor: "maroon",
									borderRadius: "10px 10px 0 0",
								}}
							>
								<Typography variant="body1" color="white" fontWeight="bold">
									{" "}
									Последние обращения
								</Typography>
							</Box>
							<Box sx={{ padding: 2 }}>
								<Typography variant="caption" color="gray" fontWeight="bold">
									{" "}
									У вас пока нет отправленных обращений
								</Typography>
							</Box>
						</Box>

						{/* Последние начисления */}
						<Box sx={sxWidgets}>
							<Box
								sx={{
									borderBottom: "2px solid",
									padding: "5px",
									backgroundColor: "lightgreen",
									borderRadius: "10px 10px 0 0",
								}}
							>
								<Typography variant="body1" color="white" fontWeight="bold">
									{" "}
									Последние начисления
								</Typography>
							</Box>
							<Box sx={{ padding: 2 }}>
								<List>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Членский взнос октябрь 2022 1500₽" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Членский взнос ноябрь 2022 1500₽" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Членский взнос декабрь 2022 1300₽" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="20/09/2022 17.35 Целевой взнос на укладку дороги 2000₽" />
										</ListItemButton>
									</ListItem>
								</List>
							</Box>
						</Box>

						{/* Автоматизация */}
						<Box sx={sxWidgets}>
							<Box
								sx={{
									borderBottom: "2px solid",
									padding: "5px",
									backgroundColor: "darkorange",
									borderRadius: "10px 10px 0 0",
								}}
							>
								<Typography variant="body1" color="white" fontWeight="bold">
									{" "}
									Объекты автоматизации
								</Typography>
							</Box>
							<Box sx={{ padding: 2 }}>
								<List>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="Открыть шлагбаум" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="Открыть ворота" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton>
											<ListItemText primary="Открыть калитку (лес)" />
										</ListItemButton>
									</ListItem>
								</List>
							</Box>
						</Box>
					</Box>
				</>
			)}

			{context.userInfo.accessMode === AccessMode.ManagerMode && (
				<Box className={styles.indicatorsWrapper}>
					{indicatorBox(
						"Владения",
						<EstateIcon sx={{ fontSize: 54 }} />,
						"#800080",
						"./estates",
						[
							{ title: "ВСЕГО", indicator: dashboardData?.Estates.Total },
							{
								title: "Имеют л/с",
								indicator: dashboardData?.Estates.WithAccounts,
							},
							{
								title: "Без владельца",
								indicator: dashboardData?.Estates.WithoutOwners,
							},
						],
					)}

					{indicatorBox(
						"Персоны",
						<PersonsIcon sx={{ fontSize: 54 }} />,
						"#FF7F50",
						"./persons",
						[
							{ title: "ВСЕГО", indicator: dashboardData?.Persons.Total },
							{
								title: "Собственники",
								indicator: dashboardData?.Persons.Owners,
							},
							{
								title: "Сотрудники",
								indicator: dashboardData?.Persons.Employees,
							},
						],
					)}

					{indicatorBox(
						"Лицевые",
						<AccountIcon sx={{ fontSize: 54 }} />,
						"#AB56FF",
						"./accounts",
						[
							{ title: "ВСЕГО", indicator: dashboardData?.Accounts.Total },
							{
								title: "С долгами",
								indicator: dashboardData?.Accounts.Debt,
							},
							{
								title: "Без пользователей",
								indicator: dashboardData?.Accounts.NoUsers,
							},
						],
					)}
				</Box>
			)}
			<Box sx={{ display: "flex", flexDirection: "row", gap: 12, mt: 3 }}>
				<Box>
					<Link
						to="./automations"
						underline="none"
						component={RouterLink}
						title="Перейти в раздел Объекты автоматизации"
					>
						<Box className={styles.indicatorHorizontalWrapper}>
							<AutomationIcon sx={{ fontSize: 54 }} />
							<Typography sx={{ fontSize: 34 }}>Автоматизация</Typography>
						</Box>
					</Link>

					<Box className={styles.indicatorHorizontalWrapper}>
						{(context.userInfo.accessMode === AccessMode.ManagerMode
							? hoaAutomations
							: userAutomations
						).map((item) => (
							<Button
								variant="contained"
								key={item.Id}
								className={styles.automationItem}
								title={item.Name}
								sx={{
									"backgroundColor": `#${item.ButtonColor.replace(
										/(..)(......)/,
										"$2$1",
									)}`,
									"&:hover": {
										backgroundColor: darken(
											`#${item.ButtonColor.replace(/(..)(......)/, "$2$1")}`,
											0.2,
										),
									},
									"p": 1,
								}}
								onClick={() =>
									activateAutomation(item as unknown as AutomationBasic)
								}
							>
								<Box className={styles.automationItemName}>{item.Name}</Box>
								<Box>
									{activatedAutomations.includes(item.Id) ? (
										<CircularProgress
											sx={{
												width: "32px !important",
												height: "32px !important",
												color: "white",
											}}
										/>
									) : (
										item.ButtonSvg && (
											<IconComp
												icon={item.ButtonSvg as keyof typeof MUIcon}
												sx={{ fontSize: 32 }}
											/>
										)
									)}
								</Box>
							</Button>
						))}
					</Box>
				</Box>
				{context.userInfo.accessMode === AccessMode.ManagerMode && (
					<Box className={styles.debtIndicatorItemWrapper}>
						<Box
							className={styles.debtIndicatorItem}
							sx={{ bgcolor: "#FE9A9A" }}
							title="Сумма средств, находящаяся на отрицательных балансах лицевых счетов"
						>
							<Typography sx={{ textAlign: "start", flexGrow: 1 }}>
								Задолженность
							</Typography>
							<Typography sx={{ textAlign: "end", flexGrow: 1, fontSize: 32 }}>
								{dashboardData?.TotalDebt} ₽
							</Typography>
						</Box>

						<Box
							className={styles.debtIndicatorItem}
							sx={{ bgcolor: "#00745F" }}
							title="Сумма средств, находящаяся на положительных балансах лицевых счетов"
						>
							<Typography sx={{ textAlign: "end", flexGrow: 1, fontSize: 32 }}>
								{dashboardData?.TotalProfit} ₽
							</Typography>
							<Typography sx={{ textAlign: "start", flexGrow: 1 }}>
								Пофицит
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
}
