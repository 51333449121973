import * as React from "react";
import Divider from "@mui/material/Divider";

// icons
import DraftsIcon from "@mui/icons-material/Drafts";
import HomeIcon from "@mui/icons-material/Home";
import ContactMailIcon from "@mui/icons-material/ContactMail";

// components
import { CommonItemChangeHandler, EditControlProps } from "../../CommonComponents/CommonTypes";
import {
	makeEditElement,
	createNavMenuItem,
	NavMenuList,
} from "../../CommonComponents/EditControlBasics";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Estate } from "@model/Estate";
import EstateOwnersListControl from "../Owners/EstateOwnersListControl";
import Context from "@infra/Context";

enum EstateEditPageType {
	/** Основная информация. */
	main,
	/** Собственники владения. */
	owners,
	/** Обращения (заявления). */
	requests,
}

function showEstateOwners(item: Estate) {
	return <EstateOwnersListControl estate={item} />;
}

function showUserRequests(item: Estate) {
	return <div>Обращения {item.Name}</div>;
}

export default function EstateEditControl(props: EditControlProps<Estate>) {
	const [selectedPage, setPage] = React.useState(EstateEditPageType.main);
	const context = React.useContext(Context);

	const showMainInfo = (item: Estate, onValueChange: CommonItemChangeHandler<Estate>) => {
		return (
			<form autoComplete="off">
				{makeEditElement(
					`№ ${
						context.userInfo.activeHoa?.ItemNameCases?.GenitiveSingle.toLowerCase() ??
						"владения"
					}`,
					item,
					"LocalNumber",
					onValueChange,
					true,
				)}
				{makeEditElement("Название", item, "Name", onValueChange, false)}
				{makeEditElement("Площадь (м2)", item, "Square", onValueChange, false)}
				{makeEditElement("Кадастровый номер", item, "KadNumber", onValueChange, false)}

				<Divider />

				{makeEditElement("Примечание", item, "Remark", onValueChange, false)}
			</form>
		);
	};

	const valueChangeHandler: CommonItemChangeHandler<Estate> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
	};

	const handleListItemClick = (event: React.MouseEvent, page: EstateEditPageType) => {
		setPage(page);
	};

	const showEditTool = (page: EstateEditPageType, item: Estate | null) => {
		if (item == null) return null;

		switch (page) {
			case EstateEditPageType.main:
				return showMainInfo(item, valueChangeHandler);
			case EstateEditPageType.requests:
				return showUserRequests(item);
			case EstateEditPageType.owners:
				return showEstateOwners(item);
			default:
				return null;
		}
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<EstateEditPageType>>
					{[
						createNavMenuItem<EstateEditPageType>(
							EstateEditPageType.main,
							() => selectedPage === EstateEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),
						createNavMenuItem<EstateEditPageType>(
							EstateEditPageType.owners,
							() => selectedPage === EstateEditPageType.owners,
							() => props.mode === "edit",
							"Владельцы",
							ContactMailIcon,
							handleListItemClick,
						),
						createNavMenuItem<EstateEditPageType>(
							EstateEditPageType.requests,
							() => selectedPage === EstateEditPageType.requests,
							() => props.mode === "edit",
							"Обращения",
							DraftsIcon,
							handleListItemClick,
						),
					]}
				</NavMenuList>
			}
		>
			{showEditTool(selectedPage, props.editItem)}
		</EditorMainWrapper>
	);
}
