import { Account } from "@model/Account";
import { InteractiveApiProps, processPromiseResult } from "..";

const methods = {
	fetchHoaAccounts(hoaId: number | undefined, props: InteractiveApiProps<Account[]>) {
		import("./get-hoa-account-list").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
	fetchUserAccounts(props: InteractiveApiProps<Account[]>) {
		import("./get-user-account-list").then((module) => {
			processPromiseResult(() => module.default(), props);
		});
	},
	/*  fetchAccount(id: number,
          props: InteractiveApiProps<Account>) {
          import("./get-account").then((module) => {
              module.default(id)
                  .then(person => {
                      if (person)
                          props.onDataLoaded(person);
                      props.onFinish();
                  })
                  .catch(reason => {
                      props.onShowInfo(`Произошла ошибка при загрузке данных. ${reason}`, { variant: 'error' });
                      props.onFinish();
                  });
          });
      },*/
	saveAccount(account: Account, hoaId: number, props: InteractiveApiProps<Account>) {
		import("./save-account").then((module) => {
			processPromiseResult(() => module.default(account, hoaId), props);
		});
	},
};

export default methods;
