import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { EditControlProps } from "../CommonTypes";

// main code =======================================================

export interface EditDialogProps<T> {
	onclose?: () => void;
	onsave: (itemToSave: T) => void;
	open: boolean;
	dialogCaption: string;
	formControl: React.FunctionComponent<EditControlProps<T>> | undefined;
	dialogContentText?: string;
	isFullScreen: boolean;
	dialogMaxSize: "xs" | "sm" | "md" | "lg" | "xl" | false;
	fullWidth: boolean;
	item: T | null;
}

export const ItemEditDialog = <T,>(props: EditDialogProps<T>) => {
	const [open, setOpen] = useState(props.open);
	const [valuesChanged, setValuesChanged] = useState(false);
	const [item, setItem] = useState<T>({} as T);

	useEffect(() => setOpen(props.open), [props.open]);
	useEffect(() => {
		setItem(props.item ?? ({} as T));
	}, [props.item]);

	const onSave = () => {
		props.onsave?.(item);
		setValuesChanged(false);
	};

	const onClose = () => {
		props.onclose?.();

		setOpen(false);
		setValuesChanged(false);
	};

	const handleValueChange: (changedFieldName: keyof T, newValue: any) => void = (
		changedFieldName: keyof T,
		newValue: any,
	) => {
		setItem((i) => {
			i[changedFieldName] = newValue;
			return i;
		});
		setValuesChanged(true);
	};

	return (
		<Dialog
			open={open && props.formControl !== undefined}
			onClose={props.onclose}
			aria-labelledby="form-dialog-title"
			maxWidth="lg"
			onBackdropClick={() => null}
			fullWidth={props.fullWidth}
			fullScreen={props.isFullScreen}
		>
			<DialogTitle id="form-dialog-title">{props.dialogCaption}</DialogTitle>
			<DialogContent>
				{props.formControl && (
					<props.formControl
						editItem={item}
						mode={props.item ? "edit" : "create"}
						onValueChange={handleValueChange}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onSave}
					color="success"
					variant="contained"
					disabled={!valuesChanged}
				>
					Сохранить
				</Button>
				<Button onClick={onClose} color="error" variant="contained">
					Отмена
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ItemEditDialog;
