import * as React from "react";
import { Box } from "@mui/material";

const ToolBarDiv: React.FC<React.PropsWithChildren<object>> = (
	props: React.PropsWithChildren<object>,
) => {
	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-end",
				padding: theme.spacing(1.6, 1),
				...theme.mixins.toolbar,
			})}
		>
			{props.children}
		</Box>
	);
};

export default ToolBarDiv;
