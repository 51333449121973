import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useSnackbar } from "notistack";

import { EnhancedTable } from "@table";
import { CellData } from "@table/Interfaces/CellData";
import { TableHeadCell } from "@table/Interfaces/HeadCell";

import api from "@libs/api";
import { PeriodicityTypeNames } from "@libs/utils/periodicity";

import { Fee } from "@model/Fee";
import { AccessMode } from "@model/AccessMode";

import { getDateTextRepresentation } from "@commonComponents/EditControlBasics";

import FeeEditControl from "./FeeEditControl";
import Context, { UserContext } from "@infra/Context";
import { sxSectionContent } from "@commonComponents/layoutsSx";

export function FeesListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Fee[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const context = React.useContext(Context) as UserContext;
	const navigate = useNavigate();

	const RefreshPersonsList = () => {
		api.get("api/Fees", { hoaId: context.userInfo.activeHoa?.Id }, "json")
			.then((response) => {
				if (response && response.data) setRowCollection(response.data);
				setloading(false);
			})
			.catch(() => {
				enqueueSnackbar("Произошла ошибка при загрузке данных.", {
					variant: "error",
				});
				setloading(false);
			});
	};

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
			return;
		}
		RefreshPersonsList();
	}, []);

	const handleSaveFee = (newitem: Fee) => {
		console.log(newitem);
		api.send("api/Fees?hoaId=" + context.userInfo.activeHoa?.Id, newitem, "post").then(
			(resp) => {
				let savedFee: Fee;

				if (resp && resp.data) {
					savedFee = resp.data as Fee;

					if (savedFee) {
						setloading(true);
						RefreshPersonsList();
					}
				}
			},
		);
	};

	function getEditDialogTitle(item: Fee | null) {
		if (item) return `${item.Name}`;
		return "Новый взнос";
	}

	const headCells: TableHeadCell<Fee>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Наименование",
			canSort: true,
		},
		{
			id: "Amount",
			numeric: true,
			disablePadding: false,
			label: "Сумма",
			canSort: true,
		},
		{
			id: "Periodicity",
			numeric: false,
			disablePadding: false,
			label: "Периодичность",
			canSort: true,
		},
		{
			id: "FirstDate",
			numeric: false,
			disablePadding: false,
			label: "Дата первого начисления",
			canSort: true,
		},
		{
			id: "LastDate",
			numeric: false,
			disablePadding: false,
			label: "Дата последнего начисления",
			canSort: true,
		},
		{
			id: "Reason",
			numeric: false,
			disablePadding: false,
			label: "Основание",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
	];

	function сreateCellData(item: Fee): CellData[] {
		return [
			{ align: "left", nodeValue: item.Name },
			{ align: "right", nodeValue: <b>{item.Amount} ₽</b> },
			{ align: "left", nodeValue: PeriodicityTypeNames[item.Periodicity] },
			{ align: "left", nodeValue: getDateTextRepresentation(item.FirstDate) },
			{ align: "left", nodeValue: getDateTextRepresentation(item.LastDate) },
			{ align: "left", nodeValue: item.Reason },
			{ align: "left", nodeValue: item.Remark },
		];
	}

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			rows={rowCollection}
			headCells={headCells}
			getCellData={сreateCellData}
			dialogHeadConstructor={getEditDialogTitle}
			multiselect={false}
			editable
			editDialogSizeType="large"
			onDataSave={handleSaveFee}
			editDialogFullWidth
			canAdd
			canDelete
			denseView={false}
			itemEditControl={FeeEditControl}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				Взносы
			</Typography>
			<p>Управление членскими и целевыми взносами</p>
			{contents}
		</Box>
	);
}
