import { FC } from "react";
import { Theme, SxProps, Box, Divider, Paper } from "@mui/material";

export interface EditorMainWrapperProps {
	left?: React.ReactElement | null;
	children?: React.ReactElement | null | boolean;
}

const EditorMainWrapper: FC<EditorMainWrapperProps> = (props) => {
	const sxMainWrapper: SxProps<Theme> = (theme) => ({
		width: "100%",
		backgroundColor: theme.palette.background.default,
		display: "flex",
		flexDirection: "row",
	});

	const sxLeftMenu: SxProps<Theme> = (theme) => ({
		width: "100%",
		maxWidth: 200,
		backgroundColor: theme.palette.background.paper,
	});

	const sxRightPanel: SxProps<Theme> = {
		width: "100%",
		background:
			"linear-gradient(180deg, rgba(255,249,212,1) 0%, rgba(255,255,255,1) 35%, rgba(200,236,244,1) 93%)",
		// backgroundColor: theme.palette.background.default,
		padding: 1,
		overflowY: "auto",
		display: "flex",
		flexDirection: "column",
	};

	return (
		<Box sx={sxMainWrapper}>
			{props.left ? (
				<Box sx={sxLeftMenu}>
					{props.left}
					<Divider />
				</Box>
			) : null}
			{props.children ? <Paper sx={sxRightPanel}>{props.children}</Paper> : null}
		</Box>
	);
};

export default EditorMainWrapper;
