import * as React from "react";
import Divider from "@mui/material/Divider";

// icons
import HomeIcon from "@mui/icons-material/Home";

// components
import { CommonItemChangeHandler, EditControlProps } from "../../CommonComponents/CommonTypes";
import {
	makeEditElement,
	createNavMenuItem,
	NavMenuList,
	MaskedEditElement,
} from "../../CommonComponents/EditControlBasics";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Organization } from "@model/Organization";
import { Grid } from "@mui/material";

enum OrgEditPageType {
	/** Основная информация. */
	main,
}

export default function OrganizationEditControl(props: EditControlProps<Organization>) {
	const [selectedPage, setPage] = React.useState(OrgEditPageType.main);

	const onValueChange: CommonItemChangeHandler<Organization> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
	};

	const handleListItemClick = (event: React.MouseEvent, page: OrgEditPageType) => {
		setPage(page);
	};

	function showMainInfo(item: Organization) {
		return (
			<form autoComplete="off">
				{makeEditElement("Наименование", item, "Name", onValueChange, true)}

				<Grid container spacing={2} columns={6}>
					<Grid item xs={2}>
						<MaskedEditElement<Organization>
							name="ИНН"
							value={item}
							fieldName="Inn"
							onValueChange={onValueChange}
							mask="9999 999999"
						/>
					</Grid>
					<Grid item xs={2}>
						<MaskedEditElement<Organization>
							name="КПП"
							value={item}
							fieldName="Kpp"
							onValueChange={onValueChange}
							mask="9999 99999"
						/>
					</Grid>
					<Grid item xs={2}>
						<MaskedEditElement<Organization>
							name="ОГРН"
							value={item}
							fieldName="Ogrn"
							onValueChange={onValueChange}
							mask="999 9999 999999"
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2} columns={10}>
					<Grid item xs={10}>
						{makeEditElement("Адрес", item, "Address", onValueChange, false)}
					</Grid>
					<Grid item xs={3}>
						{makeEditElement("Телефон", item, "Phone", onValueChange, false)}
					</Grid>
					<Grid item xs={3}>
						{makeEditElement("Эл. почта", item, "Email", onValueChange, false)}
					</Grid>
				</Grid>

				<Divider />

				{makeEditElement("Примечание", item, "Remark", onValueChange, false)}
			</form>
		);
	}

	const showEditTool = (page: OrgEditPageType, item: Organization | null) => {
		if (item == null) return null;

		switch (page) {
			case OrgEditPageType.main:
				return showMainInfo(item);
			default:
				return null;
		}
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<OrgEditPageType>>
					{[
						createNavMenuItem<OrgEditPageType>(
							OrgEditPageType.main,
							() => selectedPage === OrgEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),
					]}
				</NavMenuList>
			}
		>
			{showEditTool(selectedPage, props.editItem)}
		</EditorMainWrapper>
	);
}
