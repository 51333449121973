import { Person } from "@model/Person";
import { InteractiveApiProps, processPromiseResult } from "..";

const methods = {
	fetchHoaPersons(hoaId: number | undefined, props: InteractiveApiProps<Person[]>) {
		import("./get-person-list").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
	fetchPerson(id: number, props: InteractiveApiProps<Person>) {
		import("./get-person").then((module) => {
			processPromiseResult(() => module.default(id), props);
		});
	},
	savePerson(person: Person, hoaId: number, props: InteractiveApiProps<Person>) {
		import("./save-person").then((module) => {
			processPromiseResult(() => module.default(person, hoaId), props);
		});
	},
};

export default methods;
