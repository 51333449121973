import { AccessMode } from "@model/AccessMode";
import { Hoa } from "@model/Hoa";
import { safeParse } from "./json-utils";

const TXT_MANAGED_HOA = "managedHoa";
const TXT_ACCESS_MODE = "accessMode";

export const storeModeState = (hoa: Hoa | null, accessMode: AccessMode) => {
	if (!hoa) {
		localStorage.removeItem(TXT_MANAGED_HOA);
	}

	localStorage.setItem(TXT_ACCESS_MODE, accessMode.toString());
	localStorage.setItem(TXT_MANAGED_HOA, JSON.stringify(hoa));
};

export const getModeStateHoa = () => {
	const activeHoaJson = localStorage.getItem(TXT_MANAGED_HOA);
	const activeHoa: Hoa | null = activeHoaJson ? safeParse<Hoa>(activeHoaJson) : null;
	return activeHoa;
};

export const getModeStateAccessMode = () => {
	const modeString = localStorage.getItem(TXT_ACCESS_MODE);
	const mode: AccessMode = modeString ? Number.parseInt(modeString) : AccessMode.ClientMode;
	return mode;
};
