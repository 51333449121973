import { SxProps } from "@mui/material";

export const sxFeeManagedButton: SxProps = {
	ml: 1,
	mb: 0.5,
};

export const sxTable: SxProps = { "minWidth": 250, "& .MuiTableCell-root": { p: "10px 0 10px 0" } };

export const sxTableContainer: SxProps = { maxHeight: 560, width: "100%" };

export const sxOverflowHiddenFlexRow: SxProps = {
	display: "flex",
	flexDirection: "row",
	overflow: "hidden",
};

export const sxYearTitle: SxProps = {
	fontSize: "2rem",
	textAlign: "center",
	flexGrow: "1",
	cursor: "default",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
};

export const sxHeaderSecondarRowCell: SxProps = {
	"&>th": {
		textAlign: "center",
		fontSize: "20px",
		p: "10px",
		top: 57,
		cursor: "default",
	},
};

export const sxMainRowsCell: SxProps = {
	"&:hover": {
		"&>td>div": {
			bgcolor: "rgba(39, 157, 0, 0.3)",
		},
	},
};

export const sxEstateNameCell: SxProps = {
	borderTopLeftRadius: 10,
	borderBottomLeftRadius: 10,
	bgcolor: "rgba(39, 157, 0, 0.18)",
	pl: 2,
	display: "flex",
	alignItems: "center",
	borderColor: "rgba(39, 157, 0, 0.5)",
	borderStyle: "solid",
	borderWidth: "1px",
	borderRightStyle: "none",
	height: "60px",
	cursor: "default",
};

export const sxPeriodCheckCell: SxProps = {
	bgcolor: "rgba(39, 157, 0, 0.18)",
	borderColor: "rgba(39, 157, 0, 0.5)",
	borderStyle: "solid",
	borderRightStyle: "none",
	borderLeftStyle: "none",
	borderWidth: "1px",
	p: 1,
};

export const sxEstateCloserCell: SxProps = {
	bgcolor: "rgba(39, 157, 0, 0.18)",
	p: 1,
	borderColor: "rgba(39, 157, 0, 0.5)",
	borderStyle: "solid",
	borderLeftStyle: "none",
	borderWidth: "1px",
	height: "60px",
	borderTopRightRadius: 10,
	borderBottomRightRadius: 10,
};
