import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Context, { UserContext } from "../../Infrastructure/Context";
import { EnhancedTable } from "@table";
import { useSnackbar } from "notistack";
import { CellData } from "@table/Interfaces/CellData";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { RoleType } from "@model/RoleType";
import { Hoa } from "@model/Hoa";
import { AccessMode } from "@model/AccessMode";
import { useNavigate } from "react-router-dom";
import { sxSectionContent } from "@commonComponents/layoutsSx";
import { Box } from "@mui/material";

// icons

function CreateCellData(item: Hoa): CellData[] {
	return [
		{ align: "left", nodeValue: item.Name },
		{ align: "left", nodeValue: item.Address },
		{ align: "left", nodeValue: item.Remark },
	];
}

export function HoaManagementListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Hoa[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const context = React.useContext(Context) as UserContext;
	const navigate = useNavigate();

	const RefreshEstatesList = () => {
		const roleItems = context.userInfo.roles;

		if (roleItems && roleItems.length > 0) {
			const hoas = roleItems
				.filter((item) => item.Role.Type === RoleType.CLIENT) // Отбираем только роли с Хозяйствами
				.map((item) => item.Hoa); // Вычленяем хозяйства из Ролей
			const uniqueHoas = [...new Map(hoas.map((hoa) => [hoa.Id, hoa])).values()]; // Выбираем только живые хозяйства и только в одном экземпляре

			if (uniqueHoas) setRowCollection(uniqueHoas);
		}

		setloading(false);
	};

	React.useEffect(() => {
		RefreshEstatesList();
	}, []);

	const headCells: TableHeadCell<Hoa>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Наименование Хозяйства",
			canSort: true,
		},
		{
			id: "Address",
			numeric: false,
			disablePadding: false,
			label: "Адрес",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
	];

	const hoaSelectionHandler = (hoa: Hoa) => {
		context.setState({
			...context.userInfo,
			activeHoa: hoa,
			accessMode: AccessMode.ManagerMode,
		});
		enqueueSnackbar(`Вы успешно вошли в режим управления хозяйством "${hoa.Name}"`, {
			variant: "success",
		});
		navigate("/", { replace: true });
	};

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			rows={rowCollection}
			headCells={headCells}
			getCellData={CreateCellData}
			multiselect={false}
			editDialogSizeType="large"
			canAdd
			denseView={false}
			canSelect
			onSelectionForce={hoaSelectionHandler}
			onDataSave={() => null}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<h1>Выберите хозяйство для управления им</h1>
			<p>Здесь отображены все хозяйства, которыми вы можете управлять</p>
			{contents}
		</Box>
	);
}
