import { AutomationExtended as Automation } from "@model/AutomationExtended";
import { InteractiveApiProps, processPromiseResult } from "..";
import { AutomationBasic } from "@model/AutomationBasic";

const methods = {
	fetchHoaAutomations(hoaId: number | undefined, props: InteractiveApiProps<Automation[]>) {
		import("./get-hoa-automation-list").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
	fetchUserAutomations(props: InteractiveApiProps<Automation[]>) {
		import("./get-user-automation-list").then((module) => {
			processPromiseResult(() => module.default(), props);
		});
	},
	saveAutomation(
		automation: Automation,
		hoaId: number | undefined,
		props: InteractiveApiProps<Automation>,
	) {
		import("./save-automation").then((module) => {
			processPromiseResult(() => module.default(automation, hoaId ?? -1), props);
		});
	},
	activateAutomation(automation: AutomationBasic, props: InteractiveApiProps<Automation>) {
		import("./activate-automation").then((module) => {
			processPromiseResult(() => module.default(automation), props);
		});
	},
};

export default methods;
