import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import { Theme, SxProps } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { TextField, Typography, SvgIconTypeMap, Box } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import MaskedTextField from "./Inputs/MaskedTextField";
import { CommonItemChangeHandler } from "./CommonTypes";

export type InputMode =
	| "none"
	| "text"
	| "tel"
	| "url"
	| "email"
	| "numeric"
	| "decimal"
	| "search"
	| undefined;

export function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
	return <ListItem button component="a" {...props} />;
}

export interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}
type EditElementProps<T> = {
	name: string;
	value: T;
	fieldName: keyof T;
	onValueChange: CommonItemChangeHandler<T>;
	autoFocus?: boolean;
	required?: boolean;
};

type MaskedEditElementProps<T> = EditElementProps<T> & {
	mask: string | (string | RegExp)[];
};

export function MaskedEditElement<T>(props: MaskedEditElementProps<T>) {
	const { name, value, fieldName, onValueChange, mask, autoFocus, required } = props;
	const [curValue, setCurValue] = useState(value[fieldName] ?? "");

	return (
		<MaskedTextField
			autoFocus={autoFocus === undefined ? false : autoFocus}
			margin="normal"
			id={"edit_key_" + fieldName.toString()}
			label={name}
			required={required}
			fullWidth
			value={curValue}
			variant="outlined"
			onChange={(event) => {
				setCurValue(event.target.value);
				onValueChange(
					value,
					fieldName,
					event.target.value as unknown as (typeof value)[keyof typeof value],
				);
			}}
			mask={mask}
		/>
	);
}

export const sxReadonlyProps: SxProps = {
	"&>.Mui-focused>.MuiOutlinedInput-notchedOutline": {
		borderColor: "gray",
	},
	"&>.Mui-focused": {
		color: "gray",
	},
};

export function makeEditElement<T>(
	name: string,
	value: T,
	fieldName: keyof T,
	onValueChange: CommonItemChangeHandler<T>,
	autoFocus: boolean = false,
	required: boolean = false,
	editType: "text" | "number" | "email" | "password" = "text",
	readonly: boolean = false,
) {
	return (
		<TextField
			autoFocus={autoFocus === undefined ? false : autoFocus}
			margin="normal"
			id={`edit_key_${String(fieldName)}`}
			label={name}
			required={required}
			fullWidth
			defaultValue={value[fieldName]}
			variant="outlined"
			onChange={(event) =>
				onValueChange(
					value,
					fieldName,
					event.target.value as unknown as (typeof value)[keyof typeof value],
				)
			}
			type={editType}
			InputProps={{
				readOnly: readonly,
			}}
			sx={readonly ? sxReadonlyProps : undefined}
		/>
	);
}

export function DateEditElementExt<T>({
	name,
	value,
	fieldName,
	onValueChange,
	autoFocus = false,
	required = false,
}: EditElementProps<T>) {
	const handleDateChange = (date: unknown) => {
		const newDate = date as Date;
		setDateValue(newDate);
		onValueChange(value, fieldName, newDate as unknown as (typeof value)[keyof typeof value]);
	};

	const processDateValue = (date: unknown): Date =>
		(typeof date === "string" ? parseISO(date) : (date as Date)) ?? new Date();

	const [dateValue, setDateValue] = useState(processDateValue(value[fieldName]));

	useEffect(() => {
		setDateValue(processDateValue(value[fieldName]));
	}, [value[fieldName]]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
			<DatePicker
				format="dd.MM.yyyy"
				label={name}
				value={dateValue}
				onChange={handleDateChange}
				slots={{
					textField: TextField,
				}}
			/>
		</LocalizationProvider>
	);
}

export const InfoElement = (props: {
	name: string;
	value: string | number | React.ReactNode;
}): JSX.Element => {
	return makeInfoElement(props.name, props.value);
};

export function makeInfoElement(name: string, value: string | number | React.ReactNode) {
	const sxInfoElement: SxProps<Theme> = {
		display: "flex",
		flexDirection: "row",
		mb: 1,
	};

	const sxInfoMainHeading: SxProps<Theme> = {
		fontSize: "1rem",
		flexBasis: "220px",
		flexShrink: 0,
	};

	const sxInfoSecondaryHeading: SxProps<Theme> = (theme) => ({
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	});

	const sxInfoSecondaryBox: SxProps<Theme> = {
		display: "flex",
		flexGrow: 1,
	};

	const isTypography = Boolean((value as string).length);

	return (
		<Box sx={sxInfoElement}>
			<Typography
				sx={sxInfoMainHeading}
				color="textPrimary"
				variant="caption"
				component="div"
			>
				{name}
			</Typography>
			{isTypography ? (
				<Typography sx={sxInfoSecondaryHeading} variant="body2" component="div">
					{value}
				</Typography>
			) : (
				<Box sx={sxInfoSecondaryBox}>{value}</Box>
			)}
		</Box>
	);
}

export function getDateTextRepresentation(
	date: Date,
	nullDateText: string = "не  указана",
): React.ReactNode {
	if (date) {
		const isoDate = parseISO(date.toString());

		if (isoDate.getFullYear() !== 1) {
			try {
				return format(isoDate, "dd.MM.yyyy");
			} catch {
				return "Формат не определен." + isoDate;
			}
		}
	}
	return <em>{nullDateText}</em>;
}

type MenuItemElement<T> = {
	code: T;
	selectedDelegate: (code: T) => boolean;
	canShowDelegate: (code: T) => boolean;
	title: string;
	icon: OverridableComponent<SvgIconTypeMap>;
	onItemClick: (event: React.MouseEvent, code: T) => void;
};

export function NavMenuList<T>(props: { children: MenuItemElement<T>[] }) {
	return (
		<List component="nav" aria-label="main mailbox folders">
			{props.children.map((item) => {
				if (item.canShowDelegate(item.code)) {
					return (
						<ListItem
							key={"ItemEditMenu" + item.code}
							button
							onClick={(event) => item.onItemClick(event, item.code)}
							selected={item.selectedDelegate(item.code)}
						>
							<ListItemIcon>
								<item.icon />
							</ListItemIcon>
							<ListItemText primary={item.title} />
						</ListItem>
					);
				} else {
					return null;
				}
			})}
		</List>
	);
}

export function createNavMenuItem<T>(
	code: T,
	selectedDelegate: (code: T) => boolean,
	canShowDelegate: (code: T) => boolean,
	title: string,
	icon: OverridableComponent<SvgIconTypeMap>,
	onItemClick: (event: React.MouseEvent, code: T) => void,
): MenuItemElement<T> {
	return { code, selectedDelegate, canShowDelegate, title, icon, onItemClick };
}

export function calcShareFraction(share: number): string {
	const denominator: number = Math.floor(1 / share);
	const result: string = `1 / ${denominator.toString()}`;
	return result;
}
