import { HoaDashboardData } from "@model/HoaDashboardData";
import { InteractiveApiProps, processPromiseResult } from "..";

const methods = {
	getHoaDashboard(hoaId: number | undefined, props: InteractiveApiProps<HoaDashboardData>) {
		import("./get-hoa-dashboard").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
	/* saveAutomation(
		automation: Automation,
		hoaId: number | undefined,
		props: InteractiveApiProps<Automation>,
	) {
		import("./save-automation").then((module) => {
			processPromiseResult(() => module.default(automation, hoaId ?? -1), props);
		});
	}, */
};

export default methods;
