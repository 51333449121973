import React, { PropsWithChildren } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
	delta: number;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, delta }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: delta,
		width: `calc(100% - ${delta}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Component: React.FC<PropsWithChildren<AppBarProps>> = (
	props: React.PropsWithChildren<AppBarProps>,
) => {
	const [open, setOpen] = React.useState(false);
	const [delta, setDelta] = React.useState(0);

	React.useEffect(() => {
		if (props.open !== undefined) {
			setOpen(props.open);
		}

		setDelta(props.delta);
	}, [props.open, props.delta]);

	return (
		<AppBar position="fixed" open={open} delta={delta}>
			{props.children}
		</AppBar>
	);
};

export default Component;
