import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Account } from "@model/Account";

/* ICONS */
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";
import { EstateType } from "@model/EstateType";

import accStyles from "./account.module.scss";

export default function AccountBadgeBar(props: { account: Account }) {
	const sxRoot: SxProps<Theme> = (theme) => ({
		pl: theme.spacing(2),
		pr: theme.spacing(1),
		position: "relative",
		flexDirection: "row",
		flexWrap: "wrap",
		width: "250px",
		height: "120px",
		alignItems: "stretch",
		borderRadius: 3,
		backgroundColor: theme.palette.success.main,
		justifyContent: "space-between",
		// flexGrow: '1',
		mr: 2,
		mb: 2,
		cursor: "pointer",
	});

	const sxTitle: SxProps<Theme> = (theme) => ({
		whiteSpace: "nowrap",
		fontWeight: "bolder",
		// ml: theme.spacing(1),
		mt: theme.spacing(1),
		overflowX: "clip",
	});

	const sxIcon: SxProps<Theme> = (/* theme */) => ({
		width: 70,
		height: 70,
		position: "absolute",
		mb: 1,
		mr: 1,
		bottom: 0,
		left: 10,
	});

	const getIcon = (objectType: EstateType) => {
		return objectType === EstateType.Village ? (
			<HomeIcon htmlColor="white" fontSize="large" sx={sxIcon} />
		) : objectType === EstateType.Apartment ? (
			<ApartmentIcon htmlColor="white" fontSize="large" sx={sxIcon} />
		) : (
			<DriveEtaIcon htmlColor="white" fontSize="large" sx={sxIcon} />
		);
	};

	return (
		<Box sx={sxRoot} className={accStyles["interactive-button"]}>
			<Typography sx={sxTitle} color="white" variant="body1" component="div">
				{props.account.Estate?.Name} в <u> {props.account.HoaName}</u>
			</Typography>
			{getIcon(props.account.Estate?.Type)}
			<Typography
				className={accStyles.balance}
				color="lightgray"
				variant="h4"
				sx={{ position: "absolute", mb: 1, mr: 1, bottom: 0, right: 0 }}
			>
				{props.account.Balance}₽
			</Typography>
		</Box>
	);
}
