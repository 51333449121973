import * as React from "react";
import Divider from "@mui/material/Divider";

// icons
import HomeIcon from "@mui/icons-material/Home";

// components
import { CommonItemChangeHandler, EditControlProps } from "../../CommonComponents/CommonTypes";
import {
	makeEditElement,
	createNavMenuItem,
	NavMenuList,
	MaskedEditElement,
	DateEditElementExt,
} from "../../CommonComponents/EditControlBasics";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Person } from "@model/Person";
import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";

enum PersonEditPageType {
	/** Основная информация. */
	main,
}

export default function PersonEditControl(props: EditControlProps<Person>) {
	const [selectedPage, setPage] = React.useState(PersonEditPageType.main);
	const [docDate, setDocDate] = React.useState<Date>(
		props.editItem?.DocumentIssuingDate ?? new Date(),
	);
	const [bornDate, setBornDate] = React.useState<Date>(props.editItem?.Birthdate ?? new Date());
	const docTypeValue = props.editItem?.DocumentType ?? 0;
	const [docType, setDocType] = React.useState(docTypeValue === 0 ? "" : "21");

	React.useEffect(() => {
		props.onValueChange?.("DocumentIssuingDate", docDate);
		props.onValueChange?.("Birthdate", bornDate);
	}, []);

	const handleDateChange = (_item: Person, fieldName: keyof Person, newValue: unknown) => {
		const newDate = (newValue as Date) ?? new Date();

		switch (fieldName) {
			case "DocumentIssuingDate":
				setDocDate(newDate);
				break;
			case "Birthdate":
				setBornDate(newDate);
				break;
		}

		props.onValueChange?.(fieldName, newDate);
	};

	const handleDocTypeChange = (e: SelectChangeEvent<string>) => {
		setDocType(e.target.value);
		props.onValueChange?.("DocumentType", e.target.value);
	};

	function showMainInfo(item: Person) {
		return (
			<form autoComplete="off">
				<Grid container spacing={2} columns={3}>
					<Grid item xs={1}>
						{makeEditElement("Фамилия", item, "Surname", onValueChange, true)}
					</Grid>
					<Grid item xs={1}>
						{makeEditElement("Имя", item, "Firstname", onValueChange, false)}
					</Grid>
					<Grid item xs={1}>
						{makeEditElement("Отчество", item, "Patronymic", onValueChange, false)}
					</Grid>
				</Grid>

				<Grid container spacing={2} columns={4}>
					<Grid item xs={1}>
						<MaskedEditElement<Person>
							name="ИНН"
							value={item}
							fieldName="TaxCode"
							onValueChange={onValueChange}
							mask="9999 99999999"
						/>
					</Grid>
					<Grid item xs={1}>
						<MaskedEditElement<Person>
							name="СНИЛС"
							value={item}
							fieldName="InsuranceCode"
							onValueChange={onValueChange}
							mask="999-999-999 99"
						/>
					</Grid>
					<Grid item xs={1}>
						<MaskedEditElement<Person>
							name="Телефон"
							value={item}
							fieldName="Phone"
							onValueChange={onValueChange}
							mask="+7 (999) 999-99-99"
						/>
					</Grid>
					<Grid item xs={1}>
						{makeEditElement("Эл. почта", item, "Email", onValueChange)}
					</Grid>
				</Grid>

				<Grid container spacing={2} columns={4} rowSpacing={0.1}>
					<Grid item xs={4}>
						{makeEditElement("Адрес", item, "Address", onValueChange, false)}
					</Grid>
				</Grid>

				<Divider />

				<Grid container spacing={2} columns={6} rowSpacing={0.1}>
					<Grid item xs={1.03}>
						<Box sx={{ mt: 2 }}>
							<DateEditElementExt<Person>
								name="Дата рождения"
								value={item}
								fieldName="Birthdate"
								onValueChange={handleDateChange}
							/>
						</Box>
					</Grid>

					<Grid item xs={1.85}>
						<FormControl sx={{ mt: 2, minWidth: 280 }}>
							<InputLabel id="demo-simple-select-label">Вид документа</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={docType}
								label="Вид документа"
								onChange={handleDocTypeChange}
							>
								<MenuItem value={21}>Паспорт РФ</MenuItem>
								<MenuItem value={3}>Свидетельство о рождении РФ</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={1}>
						<MaskedEditElement<Person>
							name="Cерия документа"
							value={item}
							fieldName="DocumentSerial"
							onValueChange={onValueChange}
							mask="99 99"
						/>
					</Grid>
					<Grid item xs={1}>
						<MaskedEditElement<Person>
							name="Номер документа"
							value={item}
							fieldName="DocumentNumber"
							onValueChange={onValueChange}
							mask="999 999"
						/>
					</Grid>
					<Grid item xs={1.12}>
						<MaskedEditElement<Person>
							name="Код подразделения"
							value={item}
							fieldName="DocumentIssuerDivisionCode"
							onValueChange={onValueChange}
							mask="999-999"
						/>
					</Grid>
					<Grid item xs={4.97}>
						{makeEditElement("Выдан кем", item, "DocumentIssuer", onValueChange, false)}
					</Grid>

					<Grid item xs={1.03}>
						<Box sx={{ mt: 2 }}>
							<DateEditElementExt<Person>
								name="Дата выдачи документа"
								value={item}
								fieldName="DocumentIssuingDate"
								onValueChange={handleDateChange}
							/>
						</Box>
					</Grid>
				</Grid>

				<Divider />

				{makeEditElement("Примечание", item, "Remark", onValueChange, false)}
			</form>
		);
	}

	const onValueChange: CommonItemChangeHandler<Person> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
	};

	const handleListItemClick = (_event: React.MouseEvent, page: PersonEditPageType) => {
		setPage(page);
	};

	const showEditTool = (page: PersonEditPageType, item: Person | null) => {
		if (item == null) return null;

		switch (page) {
			case PersonEditPageType.main:
				return showMainInfo(item);
			default:
				return null;
		}
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<PersonEditPageType>>
					{[
						createNavMenuItem<PersonEditPageType>(
							PersonEditPageType.main,
							() => selectedPage === PersonEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),
					]}
				</NavMenuList>
			}
		>
			{showEditTool(selectedPage, props.editItem)}
		</EditorMainWrapper>
	);
}
