import { FunctionComponent, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { SelectControlProps } from "../CommonTypes";

// main code =======================================================

export interface SelectDialogProps<T> {
	onclose?: () => void;
	onselect: (selectedItems: T[]) => void;
	open: boolean;
	dialogCaption: string;
	formControl: FunctionComponent<SelectControlProps<T>> | undefined;
	dialogContentText?: string;
	isFullScreen: boolean;
	dialogMaxSize: "xs" | "sm" | "md" | "lg" | "xl" | false;
	fullWidth: boolean;
}

export default function ItemSelectDialog<T>(props: SelectDialogProps<T>) {
	const [items, setItems] = useState<T[]>([]);

	useEffect(() => {
		setItems([]);
	}, [props.open]);

	const onSelect = () => {
		props.onselect?.(items);
		onClose();
	};

	const onClose = () => {
		props.onclose?.();
	};

	const itemSelectHandler = (item: T) => {
		setItems([item]);
	};

	const itemsSelectHandler = (items: T[]) => {
		setItems(items);
	};

	return (
		<Dialog
			open={props.open && props.formControl !== undefined}
			onClose={props.onclose}
			aria-labelledby="form-dialog-title"
			maxWidth="lg"
			fullWidth={props.fullWidth}
			fullScreen={props.isFullScreen}
		>
			<DialogTitle id="form-dialog-title">{props.dialogCaption}</DialogTitle>
			<DialogContent>
				{props.formControl && (
					<props.formControl
						onItemSelect={itemSelectHandler}
						onItemsSelect={itemsSelectHandler}
						onSelectionDoneCall={onSelect}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onSelect}
					color="info"
					variant="contained"
					disabled={items.length === 0}
				>
					Выбрать
				</Button>
				<Button onClick={onClose} color="error" variant="contained">
					Отмена
				</Button>
			</DialogActions>
		</Dialog>
	);
}
