import { TableHeadCell } from "./Interfaces/HeadCell";
import { Order } from "./Types";

// functions ===================================================

export function descendingComparator<T>(a: T, b: T, cell: TableHeadCell<T>) {
	const bVal =
		cell && cell.sortingValuesSelector ? cell.sortingValuesSelector(b) : b[cell.id as keyof T];
	const aVal =
		cell && cell.sortingValuesSelector ? cell.sortingValuesSelector(a) : a[cell.id as keyof T];

	if (bVal < aVal) {
		return -1;
	}

	if (bVal > aVal) {
		return 1;
	}

	return 0;
}

// function getComparator<K extends keyof any, T>(order: Order, orderBy: K): (a: { [key in K]: T }, b: { [key in K]: T }) => number
export function getComparator<T>(order: Order, cell: TableHeadCell<T>): (a: T, b: T) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, cell)
		: (a, b) => -descendingComparator(a, b, cell);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}
