import { createTheme, CSSInterpolation } from "@mui/material/styles";
import Halvar from "../assets/fonts/HalvarMittel-Rg.woff2";

const theme = createTheme({
	typography: {
		fontFamily: "Halvar Mittelschrift",
	},
	palette: {
		primary: {
			main: "#279d00",
			contrastText: "#fff",
		},
		secondary: {
			main: "#70c92c",
			contrastText: "#fff",
		},
		background: {
			default: "#efefef",
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"backgroundColor": "white",
					"&.Mui-disabled": <CSSInterpolation>{
						backgroundColor: "#eee",
						opacity: 0.7,
					},
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
			  @font-face {
				font-family: "Halvar Mittelschrift";
				src: url(${Halvar}) format('woff2');
			}`,
		},
	},
});

export default theme;
