import { EnhancedTableHeadProps } from "./Interfaces/HeadProps";
import { HeadCellIdType } from "./Types";

// MUI
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

export function EnhancedTableHead<T>(props: EnhancedTableHeadProps<T>) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler =
		(property: HeadCellIdType<T>) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	const selectAllCell = () => {
		return props.isMultiselect ? (
			<TableCell padding="checkbox">
				<Checkbox
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{ "aria-label": "select all items" }}
				/>
			</TableCell>
		) : null;
	};

	return (
		<TableHead sx={{ position: "sticky", top: 0, bgcolor: "white" }}>
			<TableRow>
				{selectAllCell()}
				{props.headCells.map((headCell, index) => (
					<TableCell
						sx={{ borderBottom: ".5px solid lightgrey", whiteSpace: "nowrap" }}
						key={headCell.id as string}
						align={headCell.numeric ? "right" : "left"}
						padding={
							(headCell.disablePadding || index === 0) && props.isMultiselect
								? "none"
								: "normal"
						}
						sortDirection={orderBy === headCell.id ? order : false}
						size={headCell.size}
					>
						{headCell.canSort ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={
									orderBy === headCell.id
										? order === "asc"
											? "desc"
											: "asc"
										: "asc"
								}
								onClick={createSortHandler(headCell.id)}
							>
								<strong> {headCell.label} </strong>
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "сортировка по убыванию"
											: "сортировка по возрастанию"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							<strong> {headCell.label} </strong>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
