import {
	useEffect,
	useState,
	MouseEvent,
	ChangeEvent,
	ReactElement,
	ReactPortal,
	ReactNode,
} from "react";

// MUI
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Components
import ItemEditDialog from "../Dialog/ItemEditDialog";
import { EnchancedTableProps } from "./Interfaces/TableProps";
import { CellData } from "./Interfaces/CellData";
import { simpleDataItem } from "../../../Interfaces/Common/simpleDataItem";
import { HeadCellIdType, Order } from "./Types";
import { EnhancedTableHead } from "./TableHead";
import { EnhancedTableToolbar } from "./Toolbar";
import { getComparator, stableSort } from "./functions";

export default function EnhancedTable<T extends simpleDataItem>(props: EnchancedTableProps<T>) {
	const [order, setOrder] = useState<Order>("asc");
	const [orderBy, setOrderBy] = useState<HeadCellIdType<T>>("Id");
	const [selected, setSelected] = useState<number[]>([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(props.denseView === undefined ? false : props.denseView);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [open, setOpen] = useState(false);
	const [activeItem, setActiveItem] = useState<T | null>(null);
	const [rows, setRows] = useState<T[]>(props.rows);

	useEffect(() => {
		setRows(props.rows);
	}, [props.rows]);

	useEffect(() => {
		const newSelected = selected.filter((id) => rows.find((r) => r.Id === id));

		if (!newSelected.find((id) => id === activeItem?.Id)) {
			setActiveItem(null);
		}

		setSelected(newSelected);
	}, [rows]);

	const handleClickOpen = (event: MouseEvent<unknown>, row: T) => {
		setActiveItem(row);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = (itemToSave: T) => {
		if (itemToSave && props.onDataSave) {
			props.onDataSave(itemToSave, activeItem as T);
		}

		handleClose();
	};

	const handleRequestSort = (event: MouseEvent<unknown>, property: HeadCellIdType<T>) => {
		if (property === "actions") return;

		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.Id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	/**
	 * Множественный, единичный выбор или открытие диалога редактирования элемента.
	 * @param event Cобытие, вызвавшее CallBack.
	 * @param row Объект, хранящийся в выбранной строке.
	 */
	const handleClick = (event: MouseEvent<HTMLTableRowElement>, row: T) => {
		if (!props.multiselect) {
			if (!props.canSelect) {
				if (props.editable) handleClickOpen(event, row);

				return;
			}
		}

		if (props.canSelect) {
			setActiveItem(row);
		}

		const id = row.Id;
		/** Позиция выбранного элемента в коллекции выбранных. */
		const selectedIndex = selected.indexOf(id);
		let newSelected: Array<number> = [];

		if (props.multiselect) {
			if (selectedIndex === -1) {
				/* Элемент не был выбран до того - добавляем в коллекцию. */
				newSelected = newSelected.concat(selected, id);
			} else if (selectedIndex === 0) {
				/* Элемент  находится в начале коллекции - оставляем в массиве все элементы, кроме первого. */
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				/* Элемент  находится в конце коллекции - оставляем в массиве все элементы, кроме последнего. */
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				/* Элемент  находится в середине коллекции - оставляем в массиве все элементы, расположенные до и после рассматриваемого. */
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}
		} else {
			newSelected = [id];
		}

		/* Вызываем внешнее событие изменения выбранных элементов. */
		props.onSelectedCountChange?.(
			rows.filter((element: T) => newSelected.includes(element.Id)),
		);

		setSelected(newSelected);
	};

	/**
	 * Событие форсированного выбора строки.
	 * @param event Cобытие, вызвавшее CallBack.
	 * @param row Объект, хранящийся в выбранной строке.
	 */
	const handleDoubleClick = (event: MouseEvent<unknown>, row: T) => {
		/* Если включен мультивыбор (выбор нескольких элементов), форсированный выбор единственного элемента невозможен.
		 * Если делегат форсированного выбора не передан, обрабатывать двойной клик не имеет смысла.*/
		if (props.multiselect) {
			return;
		}

		props.onSelectionForce?.(row);
	};

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	const createCells = (row: T, labelId: string) => {
		const cellData = props.getCellData(row);
		return cellData.map((cd: CellData, index: number) => {
			return index === 0 ? (
				<TableCell
					key={`tc_${labelId}_${index}`}
					component="td"
					id={labelId}
					scope="row"
					padding={props.multiselect ? "none" : "normal"}
					align={cd.align}
				>
					{cd.nodeValue}
				</TableCell>
			) : (
				<TableCell align={cd.align} key={`tc_${labelId}_${index}`}>
					{cd.nodeValue}
				</TableCell>
			);
		});
	};

	const createCheckBoxCell = (show: boolean, isItemSelected: boolean, labelId: string) => {
		return show ? (
			<TableCell padding="checkbox">
				<Checkbox checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
			</TableCell>
		) : null;
	};

	const editDialogCaption = () => {
		return props.dialogHeadConstructor?.(activeItem) ?? "Редактрование элемента";
	};

	const getRecursiveChildText = (reactNode: ReactNode): string => {
		if (reactNode) {
			const children = (reactNode as ReactPortal | ReactElement).props?.children || undefined;
			if (Array.isArray(reactNode)) {
				// Multiple children
				const joinedNodes: Array<string> = [];
				reactNode.forEach((node) => {
					if (typeof node === "object") joinedNodes.push(getRecursiveChildText(node));
					else if (typeof node === "string") joinedNodes.push(node.trim());
					else if (typeof node === "number") joinedNodes.push(node.toString().trim());
				});

				return joinedNodes.join(" ");
			}
			if (children === undefined) {
				if (typeof reactNode === "string") return reactNode;
				else return "";
			}
			if (typeof children === "object") {
				// Found direct child
				return getRecursiveChildText(children);
			}
			if (typeof children === "string") {
				// Found searchable string
				return children.trim();
			}
			if (typeof children === "number") {
				// Found searchable string
				return children.toString().trim();
			}
		}

		return "";
	};

	const filterDataHandler = (filterText: string) => {
		if (filterText.length) {
			const filteredRows = props.rows.filter((el) => {
				const cellsData = props.getCellData(el);
				return cellsData.some((val) =>
					getRecursiveChildText(val.nodeValue)
						.toUpperCase()
						.includes(filterText.toUpperCase()),
				);
			});

			setRows(filteredRows);
		} else {
			setRows(props.rows);
		}
	};

	const commandClickHandler = (command: string) => {
		switch (command) {
			case "add":
				setActiveItem(null);
				setOpen(true);
				break;
			case "edit":
				setOpen(true);
				break;
			case "delete":
				if (selected.length > 0 && props.deleteItems) {
					const selectedRows = rows.filter((r) => selected.includes(r.Id));
					props.deleteItems(selectedRows);
				}
				break;
			default:
				break;
		}
	};

	const getOrderedCell = (orderby: HeadCellIdType<T>) => {
		const cell = props.headCells.find((cell) => cell.id === orderby);
		return cell ? cell : props.headCells[0];
	};

	return (
		<Box sx={{ flexGrow: 1, ...props.sx, height: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					height: "100%",
					mb: 2,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<EnhancedTableToolbar
					numSelected={selected.length}
					onCommandClick={commandClickHandler}
					showAddButton={props.canAdd}
					showEditButton={props.editable}
					showDeleteButton={props.canDelete}
					onCallDataFilter={filterDataHandler}
				/>
				<TableContainer
					sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "calc(100% - 124px)" }}
				>
					<Table
						sx={{
							minWidth: 750,
							maxHeight: "100%",
							overflow: "auto",
							borderCollapse: "separate",
						}}
						aria-labelledby="tableTitle"
						size={dense ? "small" : "medium"}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							headCells={props.headCells}
							isMultiselect={props.multiselect}
						/>
						<TableBody>
							{stableSort<T>(rows, getComparator<T>(order, getOrderedCell(orderBy)))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row: T, index: number) => {
									const isItemSelected = isSelected(row.Id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row)}
											onDoubleClick={(event) => handleDoubleClick(event, row)}
											role={props.multiselect ? "checkbox" : "none"}
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.Id}
											selected={isItemSelected}
										>
											{createCheckBoxCell(
												props.multiselect,
												isItemSelected,
												labelId,
											)}
											{createCells(row, labelId)}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					sx={{
						flexShrink: 0,
					}}
					rowsPerPageOptions={[5, 10, 15, 20, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Строк на странице"
					labelDisplayedRows={({ from, to, count }) =>
						`${from}–${to} из ${count !== -1 ? count : `более чем ${to}`}`
					}
				/>
			</Paper>
			{props.denseView === undefined ? (
				<FormControlLabel
					control={<Switch checked={dense} onChange={handleChangeDense} />}
					label="Плотная компоновка"
				/>
			) : null}
			<ItemEditDialog
				onclose={handleClose}
				onsave={handleSave}
				open={open}
				dialogCaption={editDialogCaption()}
				formControl={props.itemEditControl}
				item={activeItem}
				fullWidth={
					props.editDialogFullWidth !== undefined ? props.editDialogFullWidth : false
				}
				isFullScreen={props.editDialogSizeType === "fullscreen"}
				dialogMaxSize={
					props.editDialogSizeType === "fullscreen" ||
					props.editDialogSizeType === "large"
						? "lg"
						: props.editDialogSizeType === "medium"
						? "md"
						: "sm"
				}
			/>
		</Box>
	);
}
