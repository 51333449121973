import ReactDOM from "react-dom/client";
import App from "./components/Infrastructure/App";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import theme from "./lib/theme";
import { SnackbarProvider } from "notistack";
import { ContextProvider } from "./components/Infrastructure/Context";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundaries } from "@commonComponents/ErrorBoundaries";

console.info("Запуск приложения.");
const baseUrl = "/";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<ThemeProvider theme={theme}>
		<ContextProvider>
			<BrowserRouter basename={baseUrl as string | undefined}>
				<SnackbarProvider
					anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
					variant="error"
				>
					<ErrorBoundaries>
						<App />
					</ErrorBoundaries>
				</SnackbarProvider>
			</BrowserRouter>
		</ContextProvider>
	</ThemeProvider>,
);
