import * as React from "react";
import { Link as RouterLink, Route, Routes } from "react-router-dom";
import { string, number, func, oneOfType, objectOf } from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitIcon from "@mui/icons-material/ExitToApp";

import "./styles.module.scss";
import { Button, Divider, Link, ListItemIcon, ListItemText } from "@mui/material";
import Context, { UserContext } from "@infra/Context";
import { RoleType } from "@model/RoleType";
import { AccessMode } from "@model/AccessMode";

const EXIT_APP_BUTTON_TAG = "EXIT_APP_BUTTON";
const EXIT_MANAGE_BUTTON_TAG = "EXIT_MANAGE_BUTTON_TAG";

/**
 * Компонент для отображения пользовательского меню.
 *
 * @param [className] {string} - Названия CSS классов;
 * @param [style] {React.CSSProperties} - Inline стили компонента;
 * @param [userTitle] {string} - Отображаемое имя пользователя;
 * @param [userTitle] {function} - Callback для события выхода из системы;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const UserBadge = (badgeParams: {
	className: string;
	style: React.CSSProperties;
	userTitle: string;
	onExit: React.MouseEventHandler;
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const context = React.useContext(Context) as UserContext;

	/**
	 * Обработчик открытия и закрытия меню
	 */
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.MouseEvent<Element, MouseEvent>) => {
		if (event && event.currentTarget.id === EXIT_APP_BUTTON_TAG && badgeParams.onExit) {
			badgeParams.onExit(event);
		}

		if (event && event.currentTarget.id === EXIT_MANAGE_BUTTON_TAG) {
			context.setState({
				...context.userInfo,
				activeHoa: null,
				accessMode: AccessMode.ClientMode,
			});
		}

		setAnchorEl(null);
	};

	return (
		<div style={badgeParams.style}>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				startIcon={<AccountCircleIcon fontSize="inherit" />}
				className={badgeParams.className}
				style={{ color: "#fff" }}
			>
				{badgeParams.userTitle}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<Routes>
					<Route path="/" />
					<Route
						path="*"
						element={
							<Link
								to="/"
								underline="none"
								component={RouterLink}
								color="CaptionText"
							>
								<MenuItem onClick={handleClose}>Домой</MenuItem>
							</Link>
						}
					/>
				</Routes>
				<Link to="/profile" underline="none" component={RouterLink} color="CaptionText">
					<MenuItem onClick={handleClose}>Мой профиль</MenuItem>
				</Link>
				{context.userInfo.activeHoa && (
					<MenuItem id={EXIT_MANAGE_BUTTON_TAG} onClick={handleClose}>
						ЛК пользователя
					</MenuItem>
				)}
				{context.userInfo.roles.some((item) => item.Role.Type === RoleType.CLIENT) && (
					<Link
						to="/management"
						underline="none"
						component={RouterLink}
						color="CaptionText"
					>
						<MenuItem onClick={handleClose}>Управление хозяйством</MenuItem>
					</Link>
				)}
				{context.userInfo.roles.some((item) => item.Role.Type === RoleType.SYSTEM) && (
					<Link
						to="/administration"
						underline="none"
						component={RouterLink}
						color="CaptionText"
					>
						<MenuItem onClick={handleClose}>Администрирование</MenuItem>
					</Link>
				)}
				<Divider />
				<MenuItem id={EXIT_APP_BUTTON_TAG} onClick={handleClose}>
					<ListItemIcon>
						<ExitIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>Выход</ListItemText>
				</MenuItem>
			</Menu>
		</div>
	);
};

UserBadge.propTypes = {
	className: string,
	style: objectOf(oneOfType([string, number])),
	userTitle: string,
	onExit: func.isRequired,
};

UserBadge.defaultProps = {
	className: undefined,
	style: undefined,
	userTitle: undefined,
};

export default UserBadge;
