import * as React from "react";
import Divider from "@mui/material/Divider";
import { styled, SxProps, Theme } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

// icons
import HomeIcon from "@mui/icons-material/Home";

// components
import { CommonItemChangeHandler, EditControlProps } from "../../CommonComponents/CommonTypes";
import {
	makeEditElement,
	createNavMenuItem,
	NavMenuList,
	DateEditElementExt,
} from "../../CommonComponents/EditControlBasics";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Owner } from "@model/Owner";
import { OwnerType } from "@model/OwnerType";
import { Box, Button, Grid } from "@mui/material";
import ItemSelectDialog from "../../CommonComponents/Dialog/ItemSelectDialog";
import { Person } from "@model/Person";
import { Organization } from "@model/Organization";
import PersonSelectControl from "../Person/PersonSelectControl";
import OrganizationSelectControl from "../Organization/OrganizationSelectControl";

enum OwnerEditPageType {
	main,
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	"width": 150,
	"height": 80,
	"padding": 0,
	"& .MuiSwitch-switchBase": {
		"margin": 5,
		"padding": 0,
		"transform": "translateX(0px)",
		"&.Mui-checked": {
			"color": "#fff",
			"transform": "translateX(70px)",
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 2 24 24"><path fill="%23fff" d="M22 10v12H2V10l7-3v2l5-2v3h8zm-4.8-1.5L18 2h3l.8 6.5h-4.6zM11 18h2v-4h-2v4zm-4 0h2v-4H7v4zm10-4h-2v4h2v-4z"/></svg>')`,
			},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		"backgroundColor": theme.palette.mode === "dark" ? "#003892" : "#001e3c",
		"width": 70,
		"height": 70,
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24"><path fill="%23fff" d="M14 7h-4c-1.1 0-2 .9-2 2v6h2v7h4v-7h2V9c0-1.1-.9-2-2-2z"></path><circle fill="%23fff" cx="12" cy="4" r="2"></circle></svg>')`,
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
		borderRadius: 100 / 2,
		width: 200,
	},
}));

export default function OwnerEditControl(props: EditControlProps<Owner>) {
	const [selectedPage, setPage] = React.useState<OwnerEditPageType>(OwnerEditPageType.main);
	const [ownerType, setOwnerType] = React.useState(props.editItem?.Type ?? OwnerType.Person);
	const [organizationSelectDialogOpened, setOrganizationSelectDialogOpened] =
		React.useState<boolean>(false);
	const [isHoaMember, setisHoaMember] = React.useState<boolean>(
		props.editItem?.IsHoaMember ?? false,
	);
	const [personSelectDialogOpened, setPersonSelectDialogOpened] = React.useState<boolean>(false);
	const [ownBeginDate, setOwnBeginDate] = React.useState<Date>(
		props.editItem?.BeginDate ?? new Date(),
	);
	const [ownEndDate, setOwnEndDate] = React.useState<Date>(props.editItem?.EndDate ?? new Date());

	React.useEffect(() => {
		props.onValueChange?.("BeginDate", ownBeginDate);
		props.onValueChange?.("EndDate", ownEndDate);
	}, []);

	const valueChangeHandler: CommonItemChangeHandler<Owner> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
	};

	const handleListItemClick = (event: React.MouseEvent, page: OwnerEditPageType) => {
		setPage(page);
	};

	const showMainInfo = (item: Owner) => {
		if (!item.Type) {
			item.Type = OwnerType.Person;
		}

		const getOwnerName = (item: Owner): string | undefined => {
			let ownerName: string | undefined = undefined;

			if (item.Type) {
				if (item.Type === OwnerType.Person && item.Person) {
					ownerName = item.Person.Fullname;
				} else if (item.Type === OwnerType.Organization && item.Organization) {
					ownerName = item.Organization.Name;
				}
			}

			return ownerName;
		};

		const isOwnerSelected = (item: Owner): boolean => {
			const ownerName = getOwnerName(item);

			return !!ownerName;
		};

		const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const newType = event.target.checked ? OwnerType.Organization : OwnerType.Person;
			setOwnerType(newType);

			props.onValueChange?.("Type", newType);
		};

		const handleHoaMemberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const isMember = event.target.checked;
			setisHoaMember(isMember);

			props.onValueChange?.("IsHoaMember", isMember);
		};

		const selectOwnerEntityHandler = () => {
			if (item.Type === OwnerType.Organization) {
				setOrganizationSelectDialogOpened(true);
			} else {
				setPersonSelectDialogOpened(true);
			}
		};

		/**
		 * Обработчик выбранного физического лица.
		 * @param persons - выбранные владение.
		 */
		const personSelectedHanler = (persons: Person[]) => {
			try {
				const person = persons[0];
				if (person) {
					item.Person = { ...person };
					props.onValueChange?.("Person", person);
				}
			} catch (exception) {
				console.error(exception);
			}
		};

		/** Обработчик вызова закрытия диалога выбора ФЛ. */
		const personDialogCloseCallHandler = () => {
			setPersonSelectDialogOpened(false);
		};

		/**
		 * Обработчик выбранного ЮЛ.
		 * @param orgs - выбранные владение.
		 */
		const organizationSelectedHanler = (orgs: Organization[]) => {
			try {
				const organization = orgs[0];
				if (organization) {
					item.Organization = { ...organization };
					props.onValueChange?.("Organization", organization);
				}
			} catch (exception) {
				console.error(exception);
			}
		};

		/** Обработчик вызова закрытия диалога выбора ЮЛ. */
		const organizationDialogCloseCallHandler = () => {
			setOrganizationSelectDialogOpened(false);
		};

		const handleDateChange = (_item: Owner, fieldName: keyof Owner, newValue: unknown) => {
			const newDate = (newValue as Date) ?? new Date();

			switch (fieldName) {
				case "BeginDate":
					setOwnBeginDate(newDate);
					break;
				case "EndDate":
					setOwnEndDate(newDate);
					break;
			}

			props.onValueChange?.(fieldName, newDate);
		};

		const topLabledBoxStyle: SxProps<Theme> = (/* theme */) => ({
			alignSelf: "flex-start",
			display: "flex",
			flexDirection: "column",
		});

		return (
			<>
				<form autoComplete="off">
					<FormGroup row sx={{ alignItems: "flex-start" }}>
						<FormControlLabel
							control={
								<MaterialUISwitch
									sx={{ m: 1 }}
									checked={ownerType === OwnerType.Organization}
									onChange={handleTypeChange}
									inputProps={{ "aria-label": "controlled" }}
									value="on"
								/>
							}
							label="Тип владельца"
							labelPlacement="top"
							value="start"
							sx={{ ml: 0 }}
							componentsProps={{ typography: { alignSelf: "flex-start" } }}
						/>
						<FormControlLabel
							control={
								isOwnerSelected(item) ? (
									<Box sx={topLabledBoxStyle}>
										<Typography variant="h6">{getOwnerName(item)}</Typography>
										<Button
											onClick={selectOwnerEntityHandler}
											color="info"
											variant="outlined"
											size="small"
											sx={{ mt: 1, maxWidth: "150px" }}
										>
											изменить
										</Button>
									</Box>
								) : (
									<Box sx={topLabledBoxStyle}>
										<i>владелец не выбран ...</i>
										<Button
											onClick={selectOwnerEntityHandler}
											color="info"
											variant="contained"
											size="small"
											sx={{ mt: 1 }}
										>
											Выбрать владельца
										</Button>
									</Box>
								)
							}
							label={`${
								item.Type === OwnerType.Organization ? "Наименование" : "Имя"
							} владельца`}
							labelPlacement="top"
							value="start"
							sx={{ m: 0 }}
							componentsProps={{ typography: { alignSelf: "flex-start" } }}
						/>
					</FormGroup>

					<Grid container spacing={2} columns={6}>
						<Grid item xs={1}>
							{makeEditElement(
								"Доля владения",
								item,
								"Share",
								valueChangeHandler,
								true,
							)}
						</Grid>
						<Grid item xs={2}>
							<Box sx={{ mt: 2 }}>
								<DateEditElementExt<Owner>
									name="Дата вступления в собственность"
									value={item}
									fieldName="BeginDate"
									onValueChange={handleDateChange}
								/>
							</Box>
						</Grid>
						<Grid item xs={2}>
							<Box sx={{ mt: 2 }}>
								<DateEditElementExt<Owner>
									name="Дата завершения собственности"
									value={item}
									fieldName="EndDate"
									onValueChange={handleDateChange}
								/>
							</Box>
						</Grid>
						<Grid item xs={1}>
							<FormControlLabel
								value="start"
								control={
									<Switch
										color="primary"
										checked={isHoaMember}
										onChange={handleHoaMemberChange}
										inputProps={{ "aria-label": "controlled" }}
										value="on"
									/>
								}
								label="Учредитель"
								labelPlacement="top"
							/>
						</Grid>
					</Grid>

					<Divider />

					{makeEditElement("Примечание", item, "Remark", valueChangeHandler, false)}
				</form>
				{/* Диалог выбора физического лица. */}
				<ItemSelectDialog
					onclose={personDialogCloseCallHandler}
					onselect={personSelectedHanler}
					open={personSelectDialogOpened}
					dialogCaption="Выберите физическое лицо"
					formControl={PersonSelectControl}
					isFullScreen={false}
					dialogMaxSize={false}
					fullWidth={false}
				/>

				{/* Диалог выбора организации. */}
				<ItemSelectDialog
					onclose={organizationDialogCloseCallHandler}
					onselect={organizationSelectedHanler}
					open={organizationSelectDialogOpened}
					dialogCaption="Выберите юридическое лицо"
					formControl={OrganizationSelectControl}
					isFullScreen={false}
					dialogMaxSize={false}
					fullWidth={false}
				/>
			</>
		);
	};

	const showEditTool = (page: OwnerEditPageType, item: Owner | null) => {
		if (item == null) return null;

		switch (page) {
			case OwnerEditPageType.main:
				return showMainInfo(item);

			default:
				return null;
		}
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<OwnerEditPageType>>
					{[
						createNavMenuItem<OwnerEditPageType>(
							OwnerEditPageType.main,
							() => selectedPage === OwnerEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),
						// createNavMenuItem<OwnerEditPageType>('requests', p => selectedPage === 'requests', p => props.mode === 'edit', "Обращения", DraftsIcon, handleListItemClick),
					]}
				</NavMenuList>
			}
		>
			{showEditTool(selectedPage, props.editItem)}
		</EditorMainWrapper>
	);
}
