import jwtDecode from "jwt-decode";
import api from "../api";
import { apiClient } from "@api";
import { UserContext } from "@infra/Context";
import * as LS from "./local-storage-utils";

export const tokenName = "tokenJWT";

type TokenPayload = {
	sub: string;
	email: string;
	jti: string;
	exp: number;
	iss: string;
	aud: string;
};

/**
 * Функция для установки сессионных переменных.
 *
 * @param token {string} - токен пользователя;
 * @param context {UserContext} - контекст с данными из токена;
 *
 * @returns {Promise<void>}
 */
export const processToken = async (token: string, context: UserContext): Promise<boolean> => {
	api.setToken(token);
	localStorage.setItem(tokenName, token);

	try {
		const payload: TokenPayload = jwtDecode(token);
		const userId = Number.parseInt(payload.sub);
		const user = await apiClient.fetchUserById(userId);
		const roles = await apiClient.fetchUserRoles(userId);

		const mode = LS.getModeStateAccessMode();
		const hoa = LS.getModeStateHoa();

		context.setState({
			...user,
			roles: roles,
			accessMode: mode,
			activeHoa: hoa ?? context.userInfo.activeHoa,
		});
		return true;
	} catch {
		return false;
	}
};
