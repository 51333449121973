import Communicate from "communicate-api";
import { AxiosRequestConfig } from "communicate-api/node_modules/axios";

const { session } = new Communicate({
	devUrl: "http://localhost:8083/", // 55875 для запуска через IIS
	prodUrl: "https://ojigovo.ru/",
});

const commonHandlers = {} as { onLogoutHandler?: () => void | undefined };

const api = {
	/**
	 * Метод для установки токена авторизации в заголовок.
	 *
	 * @param {string | null} token - Токен авторизации;
	 */
	setToken: (token: string | null) => {
		if (token) {
			session.defaults.headers.Authorization = `Bearer ${token}`;
		} else {
			delete session.defaults.headers.Authorization;
		}
	},

	setLogoutHandler: (onLogout: () => void) => {
		commonHandlers.onLogoutHandler = onLogout;
	},

	/**
	 * Метод для получения данных с сервера.
	 *
	 * @param url {string} - URL запроса;
	 * @param [params] {object} - Параметры запроса;
	 * @param [responseType] {'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'} - Тип,
	 * в который будет конвертировано тело ответа;
	 *
	 * @returns {Promise<unknown>}
	 */
	get: async (
		url: string,
		params = {},
		responseType: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream",
	) =>
		await session
			.get(url, { params, responseType })
			.then((response) => response)
			.catch((error) => {
				console.error(error);
				if (error.response.status === 401) {
					// redirect to login
					console.info("Не найдена авторизация. Выход из приложения.");
					if (commonHandlers.onLogoutHandler) {
						commonHandlers.onLogoutHandler();
					} else {
						console.info("Выход из приложения. Обработчик не найден.");
					}

					return Promise.resolve(null);
				} else {
					return Promise.reject(error);
				}
			}),
	/**
	 * Метод для изменения данных на сервер.
	 *
	 * @param url {string} - URL запроса;
	 * @param data {object} - Передаваемые данные;
	 * @param method { 'delete' | 'post' | 'put' | 'update'} - HTTP метод запроса;
	 *
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	send: async (
		url: string,
		data: object = {},
		method: "delete" | "post" | "put" | "update" = "post",
	) => await session({ url, data, method } as AxiosRequestConfig),
};

export default api;
