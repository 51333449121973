import * as React from "react";
import { useSnackbar } from "notistack";
import { apiClient } from "@api";
import Context, { UserContext } from "@infra/Context";

import { LinearProgress } from "@mui/material";
import EditorMainWrapper from "@commonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { getDateTextRepresentation } from "@commonComponents/EditControlBasics";
import { SelectControlProps } from "@commonComponents/CommonTypes";
import { Person } from "@model/Person";
import { EnhancedTable } from "@table";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { CellData } from "@table/Interfaces/CellData";

export default function PersonSelectControl(props: SelectControlProps<Person>) {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Person[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const context = React.useContext(Context) as UserContext;

	React.useEffect(() => {
		apiClient.fetchHoaPersons(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	}, []);

	function CreateCellData(item: Person): CellData[] {
		return [
			{ align: "left", nodeValue: item.Fullname },
			{ align: "right", nodeValue: getDateTextRepresentation(item.Birthdate) },
			{ align: "left", nodeValue: item.TaxCode },
			{ align: "left", nodeValue: item.Remark },
		];
	}

	const headCells: TableHeadCell<Person>[] = [
		{
			id: "Fullname",
			numeric: false,
			disablePadding: false,
			label: "Имя физического лица",
			canSort: true,
			sortingValuesSelector: (value: Person) => value.Fullname,
		},
		{
			id: "Birthdate",
			numeric: true,
			disablePadding: false,
			label: "Дата рождения",
			canSort: true,
		},
		{ id: "TaxCode", numeric: true, disablePadding: false, label: "ИНН", canSort: true },
		{ id: "Remark", numeric: false, disablePadding: false, label: "Примечание", canSort: true },
	];

	const singleItemSelectHandler = (estate: Person) => {
		props.onItemSelect?.(estate);
		props.onSelectionDoneCall?.();
	};

	const selectedCountChangeHandler = (estates: Person[]) => {
		props.onItemsSelect?.(estates);
	};

	return (
		<EditorMainWrapper>
			{loading ? (
				<div>
					<em>Загрузка физических лиц...</em>
					<LinearProgress color="primary" />
				</div>
			) : (
				<EnhancedTable
					rows={rowCollection}
					headCells={headCells}
					getCellData={CreateCellData}
					multiselect={false}
					editDialogSizeType="large"
					onDataSave={() => null}
					editDialogFullWidth
					denseView={false}
					canSelect
					onSelectionForce={singleItemSelectHandler}
					onSelectedCountChange={selectedCountChangeHandler}
				/>
			)}
		</EditorMainWrapper>
	);
}
