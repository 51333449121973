import { User } from "@model/User";
import { InteractiveApiProps, processPromiseResult } from "..";

const methods = {
	fetchUserById(id: number) {
		return import("./get-user").then((module) => {
			return module.default(id);
		});
	},
	fetchUserRoles(id: number) {
		return import("./get-user-roles").then((module) => {
			return module.default(id);
		});
	},
	fetchHoaUsers(hoaId: number | undefined, props: InteractiveApiProps<User[]>) {
		import("./get-user-list").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
};

export default methods;
