import * as React from "react";

import { LinearProgress } from "@mui/material";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Organization } from "@model/Organization";
import { EnhancedTable } from "../../CommonComponents/EnhancedTable/";
import api from "../../../lib/api";
import { SelectControlProps } from "../../CommonComponents/CommonTypes";
import { useSnackbar } from "notistack";
import { TableHeadCell } from "../../CommonComponents/EnhancedTable/Interfaces/HeadCell";
import { CellData } from "../../CommonComponents/EnhancedTable/Interfaces/CellData";

export default function OrganizationSelectControl(props: SelectControlProps<Organization>) {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Organization[]>([]);
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		api.get("api/Organizations/", {}, "json")
			.then((response) => {
				if (response && response.data) setRowCollection(response.data);
				setloading(false);
			})
			.catch(() => {
				enqueueSnackbar("Произошла ошибка при загрузке организаций.", { variant: "error" });

				setloading(false);
			});
	}, []);

	function CreateCellData(item: Organization): CellData[] {
		return [
			{ align: "left", nodeValue: item.Name },
			{ align: "right", nodeValue: `${item.Inn} / ${item.Kpp}` },
			{ align: "left", nodeValue: "Некий адрес..." },
			{ align: "left", nodeValue: item.Remark },
		];
	}

	const headCells: TableHeadCell<Organization>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Наименование юридического лица",
			canSort: true,
			sortingValuesSelector: (value: Organization) => value.Name,
		},
		{ id: "Inn", numeric: false, disablePadding: false, label: "ИНН/КПП", canSort: true },
		{
			id: "Address",
			numeric: false,
			disablePadding: false,
			label: "Адрес регистрации",
			canSort: true,
		},
		{ id: "Remark", numeric: false, disablePadding: false, label: "Примечание", canSort: true },
	];

	const singleItemSelectHandler = (estate: Organization) => {
		props.onItemSelect?.(estate);
		props.onSelectionDoneCall?.();
	};

	const selectedCountChangeHandler = (estates: Organization[]) => {
		props.onItemsSelect?.(estates);
	};

	return (
		<EditorMainWrapper>
			{loading ? (
				<div>
					<em>Загрузка юридических лиц...</em>
					<LinearProgress color="primary" />
				</div>
			) : (
				<EnhancedTable
					rows={rowCollection}
					headCells={headCells}
					getCellData={CreateCellData}
					multiselect={false}
					editDialogSizeType="large"
					onDataSave={() => null}
					editDialogFullWidth
					denseView={false}
					canSelect
					onSelectionForce={singleItemSelectHandler}
					onSelectedCountChange={selectedCountChangeHandler}
				/>
			)}
		</EditorMainWrapper>
	);
}
