import { Organization } from "@model/Organization";
import { InteractiveApiProps, processPromiseResult } from "..";

const methods = {
	fetchHoaOrganizations(hoaId: number | undefined, props: InteractiveApiProps<Organization[]>) {
		import("./get-organization-list").then((module) => {
			processPromiseResult(() => module.default(hoaId ?? -1), props);
		});
	},
	saveOrganization(
		org: Organization,
		hoaId: number | undefined,
		props: InteractiveApiProps<Organization>,
	) {
		import("./save-organization").then((module) => {
			processPromiseResult(() => module.default(org, hoaId ?? -1), props);
		});
	},
};

export default methods;
