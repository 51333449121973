import * as React from "react";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import AccountEditControl from "./AccountEditControl";
import { EnhancedTable } from "@table";
import { Account } from "@model/Account";
import { Box, Link, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { CellData } from "@table/Interfaces/CellData";
import Context, { UserContext } from "@infra/Context";
import { apiClient } from "@api";
import { AccessMode } from "@model/AccessMode";
import { sxSectionContent } from "@commonComponents/layoutsSx";

function EditDialogHead(item: Account | null) {
	if (item?.Id) return `Лицевой счет №${item.Id}`;
	return "Создание лицевого счета";
}

function CreateCellData(item: Account): CellData[] {
	return [
		{ align: "left", nodeValue: item.Id },
		{ align: "left", nodeValue: item.Estate.Name },
		{
			align: "right",
			nodeValue: (
				<>
					{" "}
					{item.Estate.Square} м<sup>2</sup>{" "}
				</>
			),
		},
		{
			align: "left",
			nodeValue: item.Owners.map((owner) => {
				return (
					<div key={`Owner_${owner.Id}_${item.Id}`}>
						(1/{Number((1 / owner.Share).toFixed(3))}){" "}
						<Link href="#">{owner.Person?.Fullname}</Link>
					</div>
				);
			}),
		},
		{
			align: "right",
			nodeValue: (
				<Typography color="textPrimary" variant="h6" component="div">
					{" "}
					{item.Balance} ₽
				</Typography>
			),
		},
	];
}

export function AccountsListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Account[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const context = React.useContext(Context) as UserContext;

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
		}
		refreshAccountsList();
	}, []);

	const refreshAccountsList = () => {
		setloading(true);
		apiClient.fetchHoaAccounts(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	};

	const handleSaveAccount = (newItem: Account, oldItem?: Account) => {
		if (oldItem) oldItem.Balance = newItem.Balance;

		const hoaId = context.userInfo.activeHoa?.Id;

		if (hoaId) {
			apiClient.saveAccount(newItem, hoaId, {
				onDataLoaded: refreshAccountsList,
				onFinish: () => null,
				onShowInfo: enqueueSnackbar,
			});
		} else {
			enqueueSnackbar(
				"Произошла ошибка при сохранении данных. Сохранение производится не в контексте хозяйства.",
				{ variant: "error" },
			);
		}
	};

	const headCells: TableHeadCell<Account, "EstateName" | "EstateSquare">[] = [
		{
			id: "Id",
			numeric: false,
			disablePadding: false,
			label: "№ Л/С",
			canSort: true,
		},
		{
			id: "EstateName",
			numeric: false,
			disablePadding: false,
			label: "Владение",
			canSort: true,
			sortingValuesSelector: (acc) => acc.Estate.LocalNumber,
		},
		{
			id: "EstateSquare",
			numeric: true,
			disablePadding: false,
			label: "Совокупная площадь",
			canSort: true,
		},
		{
			id: "Owners",
			numeric: false,
			disablePadding: false,
			label: "Владелец",
			canSort: false,
		},
		{
			id: "Balance",
			numeric: true,
			disablePadding: false,
			label: "Баланс",
			canSort: true,
		},
	];

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			sx={{ flexGrow: 1 }}
			rows={rowCollection}
			headCells={headCells}
			getCellData={CreateCellData}
			dialogHeadConstructor={EditDialogHead}
			multiselect={false}
			itemEditControl={AccountEditControl}
			editable
			editDialogSizeType="large"
			onDataSave={handleSaveAccount}
			editDialogFullWidth
			canAdd
			denseView
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				Лицевые счета
			</Typography>
			<p>Управление лицевыми счетами хозяйства</p>
			{contents}
		</Box>
	);
}
