import { PeriodicityType } from "@model/PeriodicityType";
import * as DFNS from "date-fns";

export const PeriodicityTypeNames: Record<PeriodicityType, string> = {
	[PeriodicityType.Once]: "Единоразово",
	[PeriodicityType.Daily]: "Ежедневно",
	[PeriodicityType.Weekly]: "Еженедельно",
	[PeriodicityType.Monthly]: "Ежемесячно",
	[PeriodicityType.Quarterly]: "Ежеквартально",
	[PeriodicityType.HalfYearly]: "Полугодично",
	[PeriodicityType.Yearly]: "Ежегодно",
};

const periodicityDateIncrements: Record<PeriodicityType, { day: number; month: number }> = {
	[PeriodicityType.Once]: {
		day: 0,
		month: 0,
	},
	[PeriodicityType.Daily]: {
		day: 1,
		month: 0,
	},
	[PeriodicityType.Weekly]: {
		day: 7,
		month: 0,
	},
	[PeriodicityType.Monthly]: {
		day: 0,
		month: 1,
	},
	[PeriodicityType.Quarterly]: {
		day: 0,
		month: 3,
	},
	[PeriodicityType.HalfYearly]: {
		day: 0,
		month: 6,
	},
	[PeriodicityType.Yearly]: {
		day: 0,
		month: 12,
	},
};

export const generatePeriodCodes = (
	dateFrom: Date,
	dateTo: Date,
	periodicity: PeriodicityType,
): Array<{ date: Date; code: string }> => {
	if (periodicity === PeriodicityType.Once) {
		return [{ date: dateFrom, code: "ONCE" }];
	}

	if (periodicity === PeriodicityType.Monthly) {
		const firstDate = DFNS.startOfMonth(dateFrom);
		const lastDate = DFNS.endOfMonth(dateTo);

		return DFNS.eachMonthOfInterval({ start: firstDate, end: lastDate }).map((date) => ({
			date: date,
			code: DFNS.format(date, "yyyyMMdd"),
		}));
	}

	return [];
};

export const getPeriodCodeFromDate = (date: Date, periodicity: PeriodicityType) => {
	if (periodicity === PeriodicityType.Once) {
		return "ONCE";
	}

	if (periodicity === PeriodicityType.Monthly) {
		return DFNS.format(DFNS.startOfMonth(date), "yyyyMMdd");
	}

	return "";
};
