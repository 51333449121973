import * as React from "react";
import { Account } from "@model/Account";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	ArrowBackIosNew as ArrowBackIosNewIcon,
	ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material/";
import Context from "@infra/Context";
import { Fee } from "@model/Fee";
import { range } from "@libs/utils/range";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import * as SX from "./FeeAccountsMapping.sx";
import { generatePeriodCodes, getPeriodCodeFromDate } from "@libs/utils/periodicity";
import { useEffect } from "react";

export type FeeAccountMappingProps = {
	accounts: Array<Account>;
	item: Fee;
};

const FeeAccountMapping: React.FC<FeeAccountMappingProps> = (props) => {
	const context = React.useContext(Context);

	const [years, setYears] = React.useState<number[]>([]);
	const [currentYear, setCurrentYear] = React.useState(0);
	const yearRef = React.useRef<HTMLElement>();

	React.useEffect(() => {
		const firstYear = new Date(props.item.FirstDate).getFullYear();
		const lastYear = new Date(props.item.LastDate).getFullYear();
		const yearArray = range(firstYear, lastYear);
		const nowYear = new Date().getFullYear();

		setCurrentYear(yearArray.includes(nowYear) ? nowYear : firstYear);
		setYears(yearArray);
	}, [props.item.FirstDate, props.item.LastDate]);

	const months = React.useMemo(
		() => new Array(12).fill(0).map((_, i) => new Date(currentYear, i)),
		[currentYear],
	);
	const periods = React.useMemo(
		() =>
			generatePeriodCodes(
				new Date(props.item.FirstDate),
				new Date(props.item.LastDate),
				props.item.Periodicity,
			),
		[props.item.FirstDate, props.item.LastDate, props.item.Periodicity],
	);

	const [periodAccountsMapping, setPeriodAccountsMapping] = React.useState<
		Record<number, Record<string, boolean>>
	>({});

	const initPeriodChecks = (checked: boolean) => {
		setPeriodAccountsMapping((v) => {
			props.accounts.forEach((acc) => {
				const accPeriodCheks = v[acc.Id] ?? {};
				periods.forEach((period) => {
					accPeriodCheks[period.code] = checked;
				});
				v[acc.Id] = accPeriodCheks;
			});

			return { ...v };
		});
	};

	useEffect(() => initPeriodChecks(false), []);

	const onPeriodCheckChange = (accountId: number, periodCode: string, checked: boolean) => {
		setPeriodAccountsMapping((v) => {
			const accPeriodCheks = v[accountId] ?? {};
			accPeriodCheks[periodCode] = checked;
			v[accountId] = accPeriodCheks;
			return { ...v };
		});
	};

	const checkAllPeriodsForAccount = (accountId: number, checked: boolean) => {
		setPeriodAccountsMapping((v) => {
			const periodChecks: Record<string, boolean> = {};
			periods.forEach((period) => {
				periodChecks[period.code] = checked;
			});
			v[accountId] = periodChecks;
			return { ...v };
		});
	};

	const checkAllAccountsForPeriod = (periodCode: string, checked: boolean) => {
		console.log(periodCode);
		setPeriodAccountsMapping((v) => {
			props.accounts.forEach((acc) => {
				const accPeriodCheks = v[acc.Id] ?? {};
				accPeriodCheks[periodCode] = checked;
				v[acc.Id] = accPeriodCheks;
			});
			return { ...v };
		});
	};

	const isCheckedAllAccountsInPeriod = (periodCode: string): boolean => {
		return props.accounts
			.map((acc) => periodAccountsMapping[acc.Id]?.[periodCode] ?? false)
			.every((value) => value);
	};

	const isCheckedAllPeriodsInAccount = (acountId: number): boolean => {
		const accountPeriods = periodAccountsMapping[acountId];
		if (!accountPeriods) {
			return false;
		}
		return accountPeriods && Object.values(accountPeriods).every((value) => value);
	};

	const createKeyFrames = (
		rightToLeft: boolean,
		fading: boolean,
	): Array<{ transform: string; opacity: string }> => {
		const framesCount = 6;
		const initialTranslate = fading ? 0 : rightToLeft ? 50 : -50;
		const targetTranslate = !fading ? 0 : rightToLeft ? -50 : 50;
		const translateDelta = (targetTranslate - initialTranslate) / (framesCount - 1);
		const initialOpacity = Number(fading);
		const targetOpacity = Number(!fading);
		const opacityDelta = (targetOpacity - initialOpacity) / (framesCount - 1);
		const frames = new Array(framesCount).fill(0).map((_, i) => ({
			transform: `translateX(${(initialTranslate + translateDelta * i).toString()}%)`,
			opacity: (initialOpacity + opacityDelta * i).toString(),
		}));

		return frames;
	};

	const onGoBack = () => {
		const resumeAnimation = () => {
			setCurrentYear((year) => (years.includes(year - 1) ? year - 1 : year));
			const keyFrames = createKeyFrames(false, false);
			yearRef.current?.animate(keyFrames, {
				duration: 300,
			});
		};

		const keyFramesFading = createKeyFrames(false, true);
		const animation = yearRef.current?.animate(keyFramesFading, {
			duration: 300,
		});
		if (!animation) {
			return;
		}

		animation.onfinish = resumeAnimation;
		animation.play();
	};

	const onGoForward = () => {
		const resumeAnimation = () => {
			setCurrentYear((year) => (years.includes(year + 1) ? year + 1 : year));
			const keyFrames = createKeyFrames(true, false);
			yearRef.current?.animate(keyFrames, {
				duration: 300,
			});
		};

		const keyFramesFading = createKeyFrames(true, true);
		const animation = yearRef.current?.animate(keyFramesFading, {
			duration: 300,
		});
		if (!animation) {
			return;
		}

		animation.onfinish = resumeAnimation;
		animation.play();
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "stretch", width: "100%" }}>
			<TableContainer sx={SX.sxTableContainer}>
				<Table sx={SX.sxTable} stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								<span>
									<Button
										sx={SX.sxFeeManagedButton}
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => initPeriodChecks(true)}
									>
										Выбрать все
									</Button>
									<Button
										sx={SX.sxFeeManagedButton}
										variant="outlined"
										color="primary"
										size="small"
										onClick={() => initPeriodChecks(false)}
									>
										Снять все
									</Button>
								</span>
							</TableCell>
							<TableCell colSpan={12}>
								<Box sx={SX.sxOverflowHiddenFlexRow}>
									<Button
										onClick={onGoBack}
										disabled={!years.includes(currentYear - 1)}
									>
										<ArrowBackIosNewIcon />
									</Button>
									<Box sx={SX.sxYearTitle} ref={yearRef}>
										{currentYear} год
									</Box>
									<Button
										onClick={onGoForward}
										disabled={!years.includes(currentYear + 1)}
									>
										<ArrowForwardIosIcon />
									</Button>
								</Box>
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
						<TableRow sx={SX.sxHeaderSecondarRowCell}>
							<TableCell>
								<Box sx={{ mr: 1 }}>
									{context.userInfo.activeHoa?.ItemNameCases.NominativeSingle ||
										"Владение"}
								</Box>
							</TableCell>
							{months.map((date) => (
								<TableCell key={date.toLocaleDateString()}>
									<Box> {format(date, "LLL", { locale: ru })} </Box>
									<Checkbox
										title="Выбрать все владения в текущем периоде"
										onChange={(_, chk) =>
											checkAllAccountsForPeriod(
												getPeriodCodeFromDate(date, props.item.Periodicity),
												chk,
											)
										}
										checked={isCheckedAllAccountsInPeriod(
											getPeriodCodeFromDate(date, props.item.Periodicity),
										)}
									/>
								</TableCell>
							))}
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.accounts
							.sort((a, b) => a.Estate.LocalNumber - b.Estate.LocalNumber)
							.map((account) => (
								<TableRow key={account.Id} sx={SX.sxMainRowsCell}>
									<TableCell>
										<Box sx={SX.sxEstateNameCell}>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															title="Выбрать все периоды для данного владения"
															checked={isCheckedAllPeriodsInAccount(
																account.Id,
															)}
															onChange={(_, chk) =>
																checkAllPeriodsForAccount(
																	account.Id,
																	chk,
																)
															}
														/>
													}
													label={account.Estate.Name}
												/>
											</FormGroup>
										</Box>
									</TableCell>
									{months.map((date) => (
										<TableCell
											key={`acc_period_cell_${
												account.Id
											}_${date.toLocaleDateString()}`}
										>
											<Box sx={SX.sxPeriodCheckCell}>
												<Checkbox
													checked={
														periodAccountsMapping[account.Id]?.[
															getPeriodCodeFromDate(
																date,
																props.item.Periodicity,
															)
														] ?? false
													}
													onChange={(_, chk) =>
														onPeriodCheckChange(
															account.Id,
															getPeriodCodeFromDate(
																date,
																props.item.Periodicity,
															),
															chk,
														)
													}
												/>
											</Box>
										</TableCell>
									))}
									<TableCell>
										<Box sx={SX.sxEstateCloserCell} />
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default FeeAccountMapping;
