import React, { PropsWithChildren } from "react";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import { styled, CSSObject, Theme } from "@mui/material/styles";

interface DrawerProps extends MuiDrawerProps {
	width: number;
	className: string;
}

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })<DrawerProps>(
	({ theme, open, width }) => ({
		width: width,
		flexShrink: 0,
		whiteSpace: "nowrap",
		boxSizing: "border-box",
		...(open && {
			...openedMixin(theme, width),
			"& .MuiDrawer-paper": openedMixin(theme, width),
		}),
		...(!open && {
			...closedMixin(theme),
			"& .MuiDrawer-paper": closedMixin(theme),
		}),
	}),
);

const Component: React.FC<PropsWithChildren<DrawerProps>> = (
	props: PropsWithChildren<DrawerProps>,
) => {
	const [open, setOpen] = React.useState(false);
	const [delta, setDelta] = React.useState(0);

	React.useEffect(() => {
		if (props.open !== undefined) {
			setOpen(props.open);
		}

		setDelta(props.width);
	}, [props.open, props.width]);

	return (
		<Drawer variant="permanent" open={open} width={delta} className={props.className}>
			{props.children}
		</Drawer>
	);
};

export default Component;
