import * as React from "react";
import { Route, Routes } from "react-router";
import api from "@libs/api";

import UserProfileEditForm from "@mc/Users/UserProfileEditForm";
import { EstatesListComponent } from "@mc/Estates/EstatesListComponent";
import { AccountsListComponent } from "@mc/Accounts/AccountsListComponent";
import { UsersListComponent } from "@mc/Users/UsersListComponent";
import { OrganizationsListComponent } from "@mc/Organization/OrganizationsListComponent";
import { PersonsListComponent } from "@mc/Person/PersonsListComponent";
import { HoaManagementListComponent } from "@mc/Hoa/HoaManagementListComponent";
import { FeesListComponent } from "@mc/Fees/FeesListComponent";

import { processToken, tokenName } from "@libs/utils/token-utils";
import Loading from "@commonComponents/Loading";
import Context, { UserContext } from "@infra/Context";
import NavMenu from "@infra/NavMenu";
import { Home } from "@pages/Home";

import "./App.scss";
import { useSnackbar } from "notistack";
import { AutomationListComponent } from "@mc/Automations/AutomationsListComponent";

const Login = React.lazy(() => import("@pages/Login"));
const Landing = React.lazy(() => import("@pages/Main"));

export default function App() {
	const [loading, setLoading] = React.useState(true);
	const [userIsAuth, setUserIsAuth] = React.useState(false);
	const context: UserContext = React.useContext(Context) as UserContext;
	const { enqueueSnackbar } = useSnackbar();

	const setUserInfo = React.useCallback(
		async (token: string): Promise<boolean> => {
			if (await processToken(token, context)) {
				return true;
			} else {
				enqueueSnackbar(
					`Произошла ошибка при загрузке токена. Проверьте соединение или попробуйте авторизоваться еще раз.`,
					{
						variant: "error",
					},
				);
				return false;
			}
		},
		[context, context.setState],
	);

	React.useEffect(() => {
		(async () => {
			if (loading) {
				const token: string | null = localStorage.getItem(tokenName);

				// TODO Если токен есть в Local Storage, то пользователь считается авторизованным.
				// Это неверное поведение, стоит добавить запрос на сервер для проверки токена.
				// И в случае невалидности токена - чистить локальное хранилище.
				setUserIsAuth(Boolean(token && (await setUserInfo(token))));
				setLoading(false);
			}
		})();
	}, []);

	const onLoginHandler = React.useCallback(
		async (token: string) => {
			await setUserInfo(token);
			setUserIsAuth(true);
		},
		[setUserInfo],
	);

	/**
	 * Обработчик события "Выход из приложения".
	 */
	const onLogoutHandler = React.useCallback(() => {
		localStorage.removeItem(tokenName);
		api.setToken(null);
		setUserIsAuth(false);
	}, []);

	React.useEffect(() => {
		api.setLogoutHandler(onLogoutHandler);
	}, [onLogoutHandler]);

	return loading ? (
		<Loading />
	) : (
		<React.Suspense fallback={<Loading />}>
			{!userIsAuth ? (
				<Routes>
					<Route
						path="/*"
						element={
							<Landing>
								<Login onLogin={onLoginHandler} />
							</Landing>
						}
					/>
				</Routes>
			) : (
				<NavMenu onLogout={onLogoutHandler}>
					<Routes>
						<Route path="/" element={<Home />} />

						<Route path="/management/*" element={<HoaManagementListComponent />} />
						<Route path="/estates/*" element={<EstatesListComponent />} />
						<Route path="/accounts/*" element={<AccountsListComponent />} />
						<Route path="/fees/*" element={<FeesListComponent />} />
						<Route path="/users/*" element={<UsersListComponent />} />
						<Route path="/organizations/*" element={<OrganizationsListComponent />} />
						<Route path="/persons/*" element={<PersonsListComponent />} />
						<Route path="/automations/*" element={<AutomationListComponent />} />

						<Route path="/profile/*" element={<UserProfileEditForm />} />
					</Routes>
				</NavMenu>
			)}
		</React.Suspense>
	);
}
