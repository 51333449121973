// components
import { Payment } from "@model/Payment";

import { HeadCell, CellData } from "@commonComponents/EnhancedTable/Interfaces/";
import { PayType } from "@model/PayType";
import { Bill } from "@model/Bill";
import { Fee } from "@model/Fee";

const _nbsp_ = "\xa0";

/* =====================   Платежи   ============================================ */
export const getPayTypeText = (pt: PayType): string => {
	switch (pt) {
		case PayType.Bank:
			return "Через банк";
		case PayType.Cash:
			return "Наличными";
		case PayType.Equiring:
			return "Платежная система";
		default:
			return "(другой способ оплаты)";
	}
};

export const paymentsTableHeadCells: HeadCell<Payment>[] = [
	{
		id: "Id",
		numeric: false,
		disablePadding: false,
		label: "№ платежа",
		canSort: true,
		size: "small",
	},
	{
		id: "TransactionDate",
		numeric: false,
		disablePadding: false,
		label: "Дата платежа",
		canSort: true,
	},
	{
		id: "Value",
		numeric: true,
		disablePadding: false,
		label: "Сумма платежа ₽",
		canSort: true,
	},
	{
		id: "PayType",
		numeric: false,
		disablePadding: false,
		label: "Тип платежа",
		canSort: true,
	},
	{
		id: "Remark",
		numeric: false,
		disablePadding: false,
		label: "Примечание",
		canSort: true,
	},
];

export const CreatePaymentsCellData = (item: Payment): CellData[] => {
	const date: Date = new Date(item.TransactionDate);
	return [
		{ align: "left", nodeValue: item.Id },
		{
			align: "left",
			nodeValue: `${date.toLocaleDateString()}${_nbsp_}${date.toLocaleTimeString()}`,
		},
		{ align: "right", nodeValue: `${item.Value}₽` },
		{ align: "left", nodeValue: getPayTypeText(item.PayType) },
		{ align: "left", nodeValue: item.Remark },
	];
};

/* =====================   Начисления   ========================================= */
export const billsTableHeadCells: HeadCell<Bill, "reason" | "target">[] = [
	{
		id: "Date",
		numeric: false,
		disablePadding: false,
		label: "Дата начисления",
		size: "small",
		canSort: true,
	},
	{
		id: "Value",
		numeric: true,
		disablePadding: false,
		label: "Сумма (₽)",
		size: "small",
		canSort: true,
	},
	{
		id: "target",
		numeric: false,
		disablePadding: false,
		label: "Назначение",
		canSort: true,
	},
	{
		id: "Period",
		numeric: false,
		disablePadding: false,
		label: "Период",
		canSort: true,
	},
	{
		id: "reason",
		numeric: false,
		disablePadding: false,
		label: "Основание",
		canSort: true,
	},
	{
		id: "Remark",
		numeric: false,
		disablePadding: false,
		label: "Примечание",
		canSort: true,
	},
];

export const CreateBillCellData = (item: Bill): CellData[] => {
	const date: Date = new Date(item.Date);
	const datePeriod: Date = new Date(item.Period);
	return [
		{
			align: "left",
			nodeValue: `${date.toLocaleDateString()}${date.toLocaleTimeString()}`,
		},
		{ align: "right", nodeValue: `${item.Value}₽` },
		{ align: "left", nodeValue: item.Fee.Name },
		{ align: "left", nodeValue: datePeriod.toLocaleDateString() },
		{ align: "left", nodeValue: item.Fee.Reason },
		{ align: "left", nodeValue: item.Remark },
	];
};

/* =====================   Взносы   ========================================= */

export const feesTableHeadCells: HeadCell<Fee, "reason" | "target">[] = [
	{
		id: "Periodicity",
		numeric: false,
		disablePadding: false,
		label: "Приодичность",
		size: "small",
		canSort: true,
	},
	{
		id: "Amount",
		numeric: true,
		disablePadding: false,
		label: "Сумма (₽)",
		size: "small",
		canSort: true,
	},
	{
		id: "Name",
		numeric: false,
		disablePadding: false,
		label: "Назначение",
		canSort: true,
	},
	{
		id: "FirstDate",
		numeric: false,
		disablePadding: false,
		label: "Дата начала",
		canSort: true,
	},
	{
		id: "LastDate",
		numeric: false,
		disablePadding: false,
		label: "Дата окончания",
		canSort: true,
	},
	{
		id: "Remark",
		numeric: false,
		disablePadding: false,
		label: "Примечание",
		canSort: true,
	},
];

export const CreateFeeCellData = (item: Fee): CellData[] => {
	const firstDate: Date = new Date(item.FirstDate);
	const lastDate: Date = new Date(item.LastDate);

	return [
		{ align: "right", nodeValue: item.Periodicity },
		{ align: "right", nodeValue: `${item.Amount}₽` },
		{ align: "left", nodeValue: item.Name },
		{ align: "left", nodeValue: firstDate.toLocaleDateString() },
		{ align: "left", nodeValue: lastDate.toLocaleDateString() },
		{ align: "left", nodeValue: item.Remark },
	];
};
