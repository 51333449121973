import * as React from "react";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";

// icons
import {
	Home as HomeIcon,
	Event as EventIcon,
	AccountBalanceWallet as AccountBalanceWalletIcon,
} from "@mui/icons-material";

// components
import { CommonItemChangeHandler, EditControlProps } from "@commonComponents/CommonTypes";
import {
	makeEditElement,
	createNavMenuItem,
	NavMenuList,
	DateEditElementExt,
	sxReadonlyProps,
} from "@commonComponents/EditControlBasics";
import EditorMainWrapper from "@commonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Fee } from "@model/Fee";
import { Account } from "@model/Account";
import { PeriodicityType } from "@model/PeriodicityType";
import {
	Box,
	Chip,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	SxProps,
	Theme,
} from "@mui/material";
import { PeriodicityTypeNames } from "@libs/utils/periodicity";
import { apiClient } from "@api";
import Context from "@infra/Context";
import { useSnackbar } from "notistack";
import FeeAccountMapping from "./FeeAccountsMapping";
import styles from "./FeeAccountMapping.module.css";
import { Loading } from "@commonComponents/index";

enum FeeEditPageType {
	/** Основная информация. */
	main,
	/** Назначение в лицевых счетах */
	accounts,
}

export default function FeeEditControl(props: EditControlProps<Fee>) {
	const periodicityValue = props.editItem?.Periodicity ?? 0;
	const [selectedPage, setPage] = useState(FeeEditPageType.main);
	const [periodicity, setPeriodicity] = useState(periodicityValue.toString());
	const [relatedAccounts, setRelatedAccounts] = useState<Account[]>();
	const [accountsIsLoading, setAccountsIsLoading] = useState(false);

	const context = React.useContext(Context);
	const { enqueueSnackbar } = useSnackbar();

	const FEE_CHECK_ALL_NAME = "fee-check-all";
	const FEE_UN_CHECK_ALL_NAME = "fee-uncheck-all";

	useEffect(() => {
		setAccountsIsLoading(true);
		apiClient.fetchHoaAccounts(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRelatedAccounts,
			onShowInfo: enqueueSnackbar,
			onFinish: () => setAccountsIsLoading(false),
		});
	}, []);

	const handlePeriodicityChange = (e: SelectChangeEvent<string>) => {
		setPeriodicity(e.target.value);
		props.onValueChange?.("Periodicity", e.target.value);
	};

	const handleFeeManageButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (event) {
			switch (event.currentTarget.id) {
				case FEE_CHECK_ALL_NAME:
					break;
				case FEE_UN_CHECK_ALL_NAME:
					break;
				default:
					break;
			}
		}
	};

	const valueChangeHandler: CommonItemChangeHandler<Fee> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
		console.log(newValue);
	};

	const handleListItemClick = (_event: React.MouseEvent, page: FeeEditPageType) => {
		setPage(page);
	};

	const showEditTool = (page: FeeEditPageType, item: Fee | null): JSX.Element | null => {
		if (item == null) return null;

		switch (page) {
			case FeeEditPageType.main:
				return showMainInfo(item);
			case FeeEditPageType.accounts:
				return showAccountsMapping(item);
			default:
				return null;
		}
	};

	function showMainInfo(item: Fee) {
		return (
			<form autoComplete="off">
				<Grid container spacing={2} columns={4}>
					<Grid item xs={2}>
						{makeEditElement(
							"Наименование взноса",
							item,
							"Name",
							valueChangeHandler,
							true,
						)}
					</Grid>
					<Grid item xs={1}>
						{makeEditElement(
							"Размер взноса (в рублях)",
							item,
							"Amount",
							valueChangeHandler,
							false,
							true,
							"number",
							item.Id > 0,
						)}
					</Grid>
					<Grid item xs={1}>
						<FormControl
							sx={{
								mt: 2,
								minWidth: 200,
								...(item.Id > 0 ? sxReadonlyProps : undefined),
							}}
						>
							<InputLabel id="fee-editform-periodicity-select-label">
								Периодичность
							</InputLabel>
							<Select
								labelId="fee-editform-periodicity-select-label"
								id="fee-editform-periodicity-select"
								value={periodicity}
								label="Периодичность"
								onChange={handlePeriodicityChange}
								inputProps={{
									readOnly: item.Id > 0,
								}}
							>
								{Object.keys(PeriodicityTypeNames).map((key) => (
									<MenuItem value={key} key={`periodicityItem_${key}`}>
										{PeriodicityTypeNames[key]}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item xs={4}>
						{makeEditElement("Основание", item, "Reason", valueChangeHandler)}
					</Grid>
				</Grid>

				<Grid container spacing={2} columns={2} rowSpacing={0.1}>
					<Grid item xs={1}>
						<Box sx={{ mt: 2 }}>
							<DateEditElementExt<Fee>
								name="Первый день списания"
								value={item}
								fieldName="FirstDate"
								onValueChange={valueChangeHandler}
							/>
						</Box>
					</Grid>

					<Grid item xs={1}>
						<Box sx={{ mt: 2 }}>
							<DateEditElementExt<Fee>
								name="Последний день списания"
								value={item}
								fieldName="LastDate"
								onValueChange={valueChangeHandler}
							/>
						</Box>
					</Grid>
				</Grid>
				<br />
				<Divider />

				{makeEditElement("Примечание", item, "Remark", valueChangeHandler, false)}
			</form>
		);
	}

	const sxFeeManagedButton: SxProps = {
		mr: 1,
		mt: 1,
	};

	const sxAccountSelector: SxProps<Theme> = {
		"borderRadius": 1,
		"bgcolor": "rgba(39, 157, 0, 0.18)",
		"p": 1,
		"borderColor": "rgba(39, 157, 0, 0.5)",
		"borderStyle": "solid",
		"borderWidth": "1px",
		"&:hover": {
			bgcolor: "rgba(39, 157, 0, 0.3)",
		},
		"display": "flex",
		"alignItems": "center",
	};

	const showAccountsMapping = (item: Fee): JSX.Element => {
		return accountsIsLoading ? (
			<Loading />
		) : (
			<>
				<span className={styles["info-span-text"]}>
					Назначение взноса: <b>{PeriodicityTypeNames[item.Periodicity]}</b>
					{item.Periodicity !== PeriodicityType.Once && (
						<i>
							{" "}
							с <b>{new Date(item.FirstDate).toLocaleDateString()}</b> по{" "}
							<b>{new Date(item.LastDate).toLocaleDateString()}</b>
						</i>
					)}
				</span>
				<span className={styles["info-span-text"]}>
					Отметьте лицевые счета
					{item.Periodicity !== PeriodicityType.Once && " и периоды"}, на которые следует
					назначить уплату текущего взноса.
				</span>
				{item.Periodicity === PeriodicityType.Once && (
					<span>
						<Button
							sx={sxFeeManagedButton}
							variant="outlined"
							color="primary"
							onClick={handleFeeManageButtonClick}
							id={FEE_CHECK_ALL_NAME}
						>
							Выбрать все
						</Button>
						<Button
							sx={sxFeeManagedButton}
							variant="outlined"
							color="primary"
							onClick={handleFeeManageButtonClick}
							id={FEE_UN_CHECK_ALL_NAME}
						>
							Отменить все
						</Button>
					</span>
				)}
				<hr />
				<Box
					sx={{
						mt: 1,
						display: "flex",
						flexDirection: "row",
						gap: 1,
						flexWrap: "wrap",
					}}
				>
					{item.Periodicity === PeriodicityType.Once &&
						relatedAccounts &&
						relatedAccounts
							.sort((a, b) => a.Estate.LocalNumber - b.Estate.LocalNumber)
							.map((account) => (
								<Box key={account.Id} sx={sxAccountSelector}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox />}
											label={account.Estate.Name}
										/>
									</FormGroup>

									{/* checked={props.checked} onChange={onChangeHandler} */}
									<Chip label="21.03.2023" color="info" icon={<EventIcon />} />
								</Box>
							))}
					{item.Periodicity === PeriodicityType.Monthly && relatedAccounts && (
						<FeeAccountMapping accounts={relatedAccounts} item={item} />
					)}
				</Box>
			</>
		);
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<FeeEditPageType>>
					{[
						createNavMenuItem<FeeEditPageType>(
							FeeEditPageType.main,
							() => selectedPage === FeeEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),

						createNavMenuItem<FeeEditPageType>(
							FeeEditPageType.accounts,
							() => selectedPage === FeeEditPageType.accounts,
							() => props.mode === "edit",
							"Назначение в Л/С",
							AccountBalanceWalletIcon,
							handleListItemClick,
						),
					]}
				</NavMenuList>
			}
		>
			{showEditTool(selectedPage, props.editItem)}
		</EditorMainWrapper>
	);
}
