import { enqueueSnackbar } from "notistack";
import React, { ErrorInfo, ReactElement } from "react";

interface ErrorBoundaryState {
	hasError: boolean;
	errorMessage: string;
}

interface ErrorboundaryProps {
	children: ReactElement;
}

export class ErrorBoundaries extends React.Component<ErrorboundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorboundaryProps) {
		super(props);
		this.state = {
			hasError: false,
			errorMessage: "",
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ hasError: true });
		this.setState({ errorMessage: error.message });
		enqueueSnackbar("Ошибка! " + error.message);
	}

	render(): React.ReactNode {
		if (this.state?.hasError) {
			console.error(this.state.errorMessage);
		}
		return this.props.children;
	}
}
