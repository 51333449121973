import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { EnhancedTable } from "@table";
import { Organization } from "@model/Organization";
import { useSnackbar } from "notistack";
import OrganizationEditControl from "./OrganizationEditControl";
import { TableHeadCell } from "@table/Interfaces/HeadCell";
import { CellData } from "@table/Interfaces/CellData";
import { Box, Typography } from "@mui/material";
import Context, { UserContext } from "@infra/Context";
import { useNavigate } from "react-router-dom";
import { AccessMode } from "@model/AccessMode";
import { apiClient } from "@api";
import { sxSectionContent } from "@commonComponents/layoutsSx";

function EditDialogHead(item: Organization | null) {
	if (item) return `${item.Name}`;
	return "Новая организация";
}

function CreateCellData(item: Organization): CellData[] {
	return [
		{ align: "left", nodeValue: item.Name },
		{ align: "left", nodeValue: item.Inn },
		{ align: "left", nodeValue: item.Kpp },
		{ align: "left", nodeValue: item.Ogrn },
		{ align: "left", nodeValue: item.Address },
		{ align: "left", nodeValue: item.Phone },
		{ align: "left", nodeValue: item.Email },
		{ align: "left", nodeValue: item.Remark },
	];
}

export function OrganizationsListComponent() {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Organization[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const context = React.useContext(Context) as UserContext;

	const refreshOrgsList = () => {
		setloading(true);
		apiClient.fetchHoaOrganizations(context.userInfo.activeHoa?.Id, {
			onDataLoaded: setRowCollection,
			onFinish: () => setloading(false),
			onShowInfo: enqueueSnackbar,
		});
	};

	React.useEffect(() => {
		if (context.userInfo.accessMode === AccessMode.ClientMode) {
			navigate("/", { replace: true });
		}
		refreshOrgsList();
	}, []);

	const handleSaveOrg = (item: Organization) => {
		apiClient.saveOrganization(item, context.userInfo.activeHoa?.Id, {
			onShowInfo: enqueueSnackbar,
			onDataLoaded: refreshOrgsList,
			onFinish: () => null,
		});
	};

	const headCells: TableHeadCell<Organization>[] = [
		{
			id: "Name",
			numeric: false,
			disablePadding: false,
			label: "Наименование",
			canSort: true,
		},
		{
			id: "Inn",
			numeric: false,
			disablePadding: false,
			label: "ИНН",
			canSort: true,
		},
		{
			id: "Kpp",
			numeric: false,
			disablePadding: false,
			label: "КПП",
			canSort: true,
		},
		{
			id: "Ogrn",
			numeric: false,
			disablePadding: false,
			label: "ОГРН",
			canSort: true,
		},
		{
			id: "Address",
			numeric: false,
			disablePadding: false,
			label: "Адрес",
			canSort: true,
		},
		{
			id: "Phone",
			numeric: false,
			disablePadding: false,
			label: "Телефон",
			canSort: true,
		},
		{
			id: "Email",
			numeric: false,
			disablePadding: false,
			label: "Email",
			canSort: true,
		},
		{
			id: "Remark",
			numeric: false,
			disablePadding: false,
			label: "Примечание",
			canSort: true,
		},
	];

	const contents = loading ? (
		<div>
			<em>Загрузка данных...</em>
			<LinearProgress color="primary" />
		</div>
	) : (
		<EnhancedTable
			rows={rowCollection}
			headCells={headCells}
			getCellData={CreateCellData}
			dialogHeadConstructor={EditDialogHead}
			multiselect={false}
			itemEditControl={OrganizationEditControl}
			editable
			editDialogSizeType="large"
			onDataSave={handleSaveOrg}
			editDialogFullWidth
			canAdd
			canDelete
			denseView={false}
		/>
	);

	return (
		<Box sx={sxSectionContent}>
			<Typography variant="h4" className="partition-title">
				Организации
			</Typography>
			<p>Редактирование справочника юридических лиц</p>
			{contents}
		</Box>
	);
}
