import * as React from "react";
import { TextField, Button, SxProps, Theme, Box } from "@mui/material";
import { EditControlProps } from "@commonComponents/CommonTypes";
import EditorMainWrapper from "@commonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Payment } from "@model/Payment";
import { PayType } from "@model/PayType";
import { DateEditElementExt } from "@commonComponents/EditControlBasics";

export default function PaymentEditControl(props: EditControlProps<Payment>) {
	const [selectedPayType, setPayType] = React.useState<PayType>(PayType.Cash);
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());

	const onValueChange = (changedFieldName: keyof Payment, newValue: any) =>
		props.onValueChange?.(changedFieldName, newValue);

	const handleDateChange = (_item: Payment, _fieldName: keyof Payment, newValue: unknown) => {
		const newDate = (newValue as Date) ?? new Date();
		setSelectedDate(newDate);
		onValueChange("TransactionDate", newDate);
	};

	React.useEffect(() => {
		onValueChange("TransactionDate", selectedDate ?? new Date());
	}, [selectedDate]);

	React.useEffect(() => {
		onValueChange("PayType", selectedPayType);
	}, [selectedPayType]);

	const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (event) {
			switch (event.currentTarget.id) {
				case "payment-button-cash":
					setPayType(PayType.Cash);
					onValueChange("PayType", PayType.Cash);
					break;
				case "payment-button-bank":
					setPayType(PayType.Bank);
					onValueChange("PayType", PayType.Bank);
					break;
				default:
					break;
			}
		}
	};

	const moneyChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
		event,
	) => {
		onValueChange("Value", parseFloat(event.target.value));
	};

	const commentChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
		event,
	) => {
		onValueChange("Remark", event.target.value);
	};

	const bankInfoChangeHandler: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (event) => {
		onValueChange("BankInfo", event.target.value);
	};

	const sxPayTypeButton: SxProps<Theme> = () => ({
		mr: 7,
	});

	return (
		<EditorMainWrapper>
			{props.editItem && (
				<>
					<Box sx={{ mb: 2 }}>
						<Button
							variant={selectedPayType === PayType.Cash ? "contained" : "outlined"}
							color="primary"
							onClick={handleButtonClick}
							id="payment-button-cash"
							sx={sxPayTypeButton}
						>
							Наличными
						</Button>
						<Button
							variant={selectedPayType === PayType.Bank ? "contained" : "outlined"}
							color="primary"
							onClick={handleButtonClick}
							id="payment-button-bank"
							sx={sxPayTypeButton}
						>
							Через банк
						</Button>
					</Box>
					<form autoComplete="off">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							{selectedPayType === PayType.Bank && (
								<DateEditElementExt<Payment>
									name="Дата платежа"
									value={props.editItem}
									fieldName="TransactionDate"
									onValueChange={handleDateChange}
								/>
							)}

							{selectedPayType === PayType.Bank && (
								<TextField
									margin="normal"
									id="payment-bankInfo"
									label="Наименование банка"
									required
									variant="outlined"
									onChange={bankInfoChangeHandler}
								/>
							)}

							<TextField
								autoFocus
								margin="normal"
								id="payment-value"
								label="Сумма платежа, ₽"
								required
								variant="outlined"
								onChange={moneyChangeHandler}
							/>

							<TextField
								margin="normal"
								id="payment-remark"
								label="Примечание"
								variant="outlined"
								onChange={commentChangeHandler}
							/>
						</Box>
					</form>
				</>
			)}
		</EditorMainWrapper>
	);
}
