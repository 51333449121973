import * as React from "react";

import { LinearProgress } from "@mui/material";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { Owner } from "@model/Owner";
import { EnhancedTable } from "../../CommonComponents/EnhancedTable/";
import api from "../../../lib/api";
import { OwnerType } from "@model/OwnerType";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ManIcon from "@mui/icons-material/Man";
import OwnerEditControl from "./OwnerEditControl";
import { useSnackbar } from "notistack";
import { Estate } from "@model/Estate";
import { calcShareFraction } from "../../CommonComponents/EditControlBasics";
import { CellData } from "../../CommonComponents/EnhancedTable/Interfaces/CellData";
import { TableHeadCell } from "../../CommonComponents/EnhancedTable/Interfaces/HeadCell";

const EstateOwnersListControl: React.FC<{ estate: Estate }> = (props) => {
	const [loading, setloading] = React.useState(true);
	const [rowCollection, setRowCollection] = React.useState<Owner[]>([]);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const [deletingItems, setDeletingItems] = React.useState<Owner[]>([]);
	const { enqueueSnackbar } = useSnackbar();

	const RefreshOwnersList = React.useCallback(() => {
		api.get("api/Owners/Estate", { estateId: props.estate.Id }, "json")
			.then((response) => {
				if (response && response.data) setRowCollection(response.data);
				setloading(false);
			})
			.catch(() => {
				enqueueSnackbar("Произошла ошибка при загрузке владельцев.", { variant: "error" });
				setloading(false);
			});
	}, []);

	React.useEffect(() => {
		RefreshOwnersList();
	}, []);

	function CreateCellData(item: Owner): CellData[] {
		return [
			{
				align: "left",
				nodeValue:
					item.Type === OwnerType.Organization ? <AccountBalanceIcon /> : <ManIcon />,
			},
			{
				align: "left",
				nodeValue:
					item.Type === OwnerType.Organization
						? item.Organization.Name
						: item.Person.Fullname,
			},
			{ align: "right", nodeValue: calcShareFraction(item.Share) },
			{ align: "left", nodeValue: item.Remark },
			{ align: "left", nodeValue: null },
		];
	}

	const headCells: TableHeadCell<Owner>[] = [
		{
			id: "Type",
			numeric: false,
			disablePadding: false,
			label: "Тип",
			canSort: true,
			sortingValuesSelector: (value: Owner) => value.Type,
		},
		{
			id: "Person",
			numeric: false,
			disablePadding: false,
			label: "Имя ФЛ/ Наименование ЮЛ",
			canSort: true,
			sortingValuesSelector: (value: Owner) =>
				value.Type === OwnerType.Organization
					? value.Organization.Name
					: value.Person.Fullname,
		},
		{ id: "Share", numeric: true, disablePadding: false, label: "Доля", canSort: true },
		{ id: "Remark", numeric: false, disablePadding: false, label: "Примечание", canSort: true },
		{ id: "actions", numeric: false, disablePadding: false, label: "Действия", canSort: false },
	];

	const singleItemSelectHandler = (owner: Owner) => {
		console.log(owner);
	};

	const selectedCountChangeHandler = (owners: Owner[]) => {
		console.log(owners);
	};

	const itemSaveHandler = (newitem: Owner) => {
		newitem.Estate = props.estate;
		api.send("api/Owners/Estate", newitem, "post").then((resp) => {
			let savedOwner: Owner;

			if (resp && resp.data) {
				savedOwner = resp.data as Owner;

				if (savedOwner) {
					RefreshOwnersList();
				}
			}
		});
	};

	const itemsDeleteHandler = (items: Owner[]) => {
		setDeleteDialogOpen(true);
		setDeletingItems(items);
	};

	const handleConfirmDeletion = () => {
		const delPromises = deletingItems.map((item) => api.send("api/Owners/", item, "delete"));

		Promise.allSettled(delPromises).then((results) => {
			const fulfilledResultPresent = results.find((result) => result.status === "fulfilled");

			if (fulfilledResultPresent) {
				RefreshOwnersList();
			}
		});

		setDeletingItems([]);
	};

	const editDialogHeadCreator = (item: Owner | null) => {
		if (item) {
			return `Редактирование владельца`;
		}

		return "Добавление владельца";
	};

	return (
		<EditorMainWrapper>
			<>
				{loading ? (
					<div>
						<em>Загрузка объектов владения...</em>
						<LinearProgress color="primary" />
					</div>
				) : (
					<EnhancedTable
						rows={rowCollection}
						headCells={headCells}
						getCellData={CreateCellData}
						editDialogSizeType="large"
						editDialogFullWidth
						denseView={false}
						itemEditControl={OwnerEditControl}
						dialogHeadConstructor={editDialogHeadCreator}
						multiselect={false}
						canAdd
						canDelete
						editable
						canSelect
						onSelectionForce={singleItemSelectHandler}
						onSelectedCountChange={selectedCountChangeHandler}
						onDataSave={itemSaveHandler}
						deleteItems={itemsDeleteHandler}
					/>
				)}
				<Dialog
					open={deleteDialogOpen}
					onClose={() => setDeleteDialogOpen(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Удаление владельца участка"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Вы подтверждаете удаление владельца участка № {props.estate.LocalNumber}
							?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDeleteDialogOpen(false)} color="error">
							Нет
						</Button>
						<Button onClick={handleConfirmDeletion} autoFocus>
							Да
						</Button>
					</DialogActions>
				</Dialog>
			</>
		</EditorMainWrapper>
	);
};

export default EstateOwnersListControl;
