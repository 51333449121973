import * as React from "react";
import Divider from "@mui/material/Divider";

// icons
import HomeIcon from "@mui/icons-material/Home";

// components
import { CommonItemChangeHandler, EditControlProps } from "../../CommonComponents/CommonTypes";
import {
	makeEditElement,
	createNavMenuItem,
	NavMenuList,
} from "../../CommonComponents/EditControlBasics";
import EditorMainWrapper from "../../CommonComponents/Dialog/DialogEditor/EditorMainWrapper";
import { AutomationExtended as Automation } from "@model/AutomationExtended";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { styled } from "@mui/material/styles";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { HttpMethod } from "@model/HttpMethod";

enum AutomationPropsBlock {
	requestParams = "RequestParams",
	responseParams = "ResponseParams",
	interfaceParams = "InterfaceParams",
}

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	"border": `1px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
		{...props}
	/>
))(({ theme }) => ({
	"backgroundColor":
		theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
	"flexDirection": "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
	backgroundColor: "#DED",
}));

enum AutomationEditPageType {
	/** Основная информация. */
	main,
}

export default function AutomationEditControl(props: EditControlProps<Automation>) {
	const [selectedPage, setPage] = React.useState(AutomationEditPageType.main);
	const [expanded, setExpanded] = React.useState<AutomationPropsBlock | false>(
		AutomationPropsBlock.requestParams,
	);
	const [method, setMethod] = React.useState<HttpMethod>(HttpMethod.GET);

	const handleChange =
		(panel: AutomationPropsBlock) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	const handleMethodChange = (e: SelectChangeEvent<number>) => {
		setMethod(Number(e.target.value));
		props.onValueChange?.("CommandType", e.target.value);
	};

	function showMainInfo(item: Automation) {
		return (
			<form autoComplete="off">
				<Grid container spacing={2} columns={3}>
					<Grid item xs={1}>
						{makeEditElement("Наименование", item, "Name", onValueChange, false, true)}
					</Grid>
					<Grid item xs={2}>
						{makeEditElement<Automation & { Description?: string }>(
							"Описание",
							item,
							"Description",
							onValueChange,
							false,
						)}
					</Grid>
				</Grid>
				<Grid container spacing={2} columns={1}>
					<Grid item xs={1}>
						{makeEditElement("Примечание", item, "Remark", onValueChange, false)}
					</Grid>
				</Grid>
				<Divider />
				<Accordion
					expanded={expanded === AutomationPropsBlock.requestParams}
					onChange={handleChange(AutomationPropsBlock.requestParams)}
				>
					<AccordionSummary>
						<Typography>Параметры формирования запроса к контроллеру</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={1}>
								<FormControl
									sx={{
										mt: 2,
										minWidth: 200,
									}}
								>
									<InputLabel id="fee-editform-periodicity-select-label">
										Тип команды (REST метод)
									</InputLabel>
									<Select
										labelId="fee-editform-periodicity-select-label"
										id="fee-editform-periodicity-select"
										value={method}
										label="Тип команды (REST метод)"
										onChange={handleMethodChange}
									>
										{Object.keys(HttpMethod).map(
											(key) =>
												String(Number(key)) !== key && (
													<MenuItem
														value={HttpMethod[key]}
														key={`periodicityItem_${key}`}
													>
														{key}
													</MenuItem>
												),
										)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								{makeEditElement(
									"Основная команда",
									item,
									"Command",
									onValueChange,
									false,
									true,
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Аутентификация",
									item,
									"Authentication",
									onValueChange,
									true,
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Содержимое POST метода",
									item,
									"RequestBody",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Тип содержимого POST метода",
									item,
									"RequestBodyContentType",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === AutomationPropsBlock.responseParams}
					onChange={handleChange(AutomationPropsBlock.responseParams)}
				>
					<AccordionSummary>
						<Typography>Параметры анализа ответа контроллера</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Ошибка, если тело ответа содержит...",
									item,
									"ResponseBodyContainFailure",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Успех, если тело ответа содержит...",
									item,
									"ResponseBodyContainSuccess",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Тип содержимого тела ответа",
									item,
									"ResponseBodyContentType",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={4}>
								{makeEditElement(
									"Ответ должен содержать код 200",
									item,
									"ResponseExpect200",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === AutomationPropsBlock.interfaceParams}
					onChange={handleChange(AutomationPropsBlock.interfaceParams)}
				>
					<AccordionSummary>
						<Typography>Параметры отображения в интерфейсе</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container spacing={2} columns={4}>
							<Grid item xs={1}>
								{makeEditElement(
									"Цвет кнопки",
									item,
									"ButtonColor",
									onValueChange,
									false,
									true,
								)}
							</Grid>
							<Grid item xs={1}>
								{makeEditElement(
									"Изображение",
									item,
									"ButtonSvg",
									onValueChange,
									false,
								)}
							</Grid>

							<Grid item xs={4}>
								{makeEditElement(
									"Сообщение об успехе",
									item,
									"SuccessMessage",
									onValueChange,
									false,
								)}
							</Grid>
							<Grid item xs={4}>
								{makeEditElement(
									"Сообщение при ошибке",
									item,
									"FailureMessage",
									onValueChange,
									false,
								)}
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</form>
		);
	}

	const onValueChange: CommonItemChangeHandler<Automation> = (
		_changedItem,
		changedFieldName,
		newValue,
	) => {
		props.onValueChange?.(changedFieldName, newValue);
	};

	const handleListItemClick = (_event: React.MouseEvent, page: AutomationEditPageType) => {
		setPage(page);
	};

	const showEditTool = (page: AutomationEditPageType, item: Automation | null) => {
		if (item == null) return null;

		switch (page) {
			case AutomationEditPageType.main:
				return showMainInfo(item);
			default:
				return null;
		}
	};

	return (
		<EditorMainWrapper
			left={
				<NavMenuList<AutomationEditPageType>>
					{[
						createNavMenuItem<AutomationEditPageType>(
							AutomationEditPageType.main,
							() => selectedPage === AutomationEditPageType.main,
							() => true,
							"Основная информация",
							HomeIcon,
							handleListItemClick,
						),
					]}
				</NavMenuList>
			}
		>
			{showEditTool(selectedPage, props.editItem)}
		</EditorMainWrapper>
	);
}
