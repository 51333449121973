import {
	Factory as FactoryIcon,
	Home as HomeIcon,
	Man as ManIcon,
	AccountBalanceWallet as AccountBalanceWalletIcon,
	Storage as StorageIcon,
	SupervisedUserCircle as SupervisedUserCircleIcon,
	Payments as PaymentsIcon,
	PrecisionManufacturing as PrecisionManufacturingIcon,
} from "@mui/icons-material";

import { AccessMode } from "@model/AccessMode";
import Context from "@infra/Context";
import * as React from "react";

const iconColor = "white";

export type menuItemsProps = {
	iconElement: JSX.Element;
	titleText: string;
	routePath: string;
	iconColor: string;
	accessMode: AccessMode;
};

export const useMenuItems = (): menuItemsProps[] => {
	const context = React.useContext(Context);

	return [
		{
			iconElement: <HomeIcon htmlColor={iconColor} />,
			titleText: "Домашняя страница",
			routePath: "/",
			iconColor: "RoyalBlue",
			// Данный пункт меню отображается во всех режимах. Отображаемая информация зависит от режима.
			accessMode:
				AccessMode.ManagerMode | AccessMode.AdministrationMode | AccessMode.ClientMode,
		},
		/* Меню для управляющего хозяйством*/
		{
			iconElement: <AccountBalanceWalletIcon htmlColor={iconColor} />,
			titleText: "Лицевые счета",
			routePath: "/accounts",
			iconColor: "magenta",
			accessMode: AccessMode.ManagerMode,
		},
		{
			iconElement: <PaymentsIcon htmlColor={iconColor} />,
			titleText: "Взносы",
			routePath: "/fees",
			iconColor: "green",
			accessMode: AccessMode.ManagerMode,
		},
		{
			iconElement: <StorageIcon htmlColor={iconColor} />,
			titleText: context.userInfo.activeHoa?.ItemNameCases?.NominativeMulti ?? "Владения",
			routePath: "/estates",
			iconColor: "purple",
			accessMode: AccessMode.ManagerMode,
		},
		{
			iconElement: <SupervisedUserCircleIcon htmlColor={iconColor} />,
			titleText: "Пользователи",
			routePath: "/users",
			iconColor: "maroon",
			accessMode: AccessMode.ManagerMode,
		},
		{
			iconElement: <ManIcon htmlColor={iconColor} />,
			titleText: "Справочник персон",
			routePath: "/persons",
			iconColor: "coral",
			accessMode: AccessMode.ManagerMode,
		},
		{
			iconElement: <FactoryIcon htmlColor={iconColor} />,
			titleText: "Справочник организаций",
			routePath: "/organizations",
			iconColor: "orange",
			accessMode: AccessMode.ManagerMode,
		},
		{
			iconElement: <PrecisionManufacturingIcon htmlColor={iconColor} />,
			titleText: "Объекты автоматизации",
			routePath: "/automations",
			iconColor: "grey",
			accessMode: AccessMode.ManagerMode,
		},
	];
};
