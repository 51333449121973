import * as React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Owner } from "@model/Owner";
import { OwnerType } from "@model/OwnerType";

type OwnerItemToggleBadgeProps = {
	owner: Owner;
	canChange?: boolean;
	checked?: boolean;
	disabled?: boolean;
	checkedChange?: (isChecked: boolean, owner: Owner) => void;
};

export default function OwnerItemToggledBadge(props: OwnerItemToggleBadgeProps) {
	const share = props.owner.Share;
	const name =
		props.owner.Type === OwnerType.Organization
			? props.owner.Organization.Name
			: props.owner.Person.Fullname;

	const onChangeHandler = (_e: React.ChangeEvent, isChecked: boolean) => {
		if (props.canChange) {
			props.checkedChange?.(isChecked, props.owner);
		}
	};

	return (
		<FormControlLabel
			disabled={props.disabled}
			label={` (1/${1 / share}) ${name}`}
			control={
				<Checkbox
					disabled={props.disabled}
					checked={props.checked}
					onChange={onChangeHandler}
				/>
			}
		/>
	);
}
