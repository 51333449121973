import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

/* ICONS */
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";

// mui
import { styled, SxProps, Theme, useTheme } from "@mui/material/styles";
import {
	Box,
	CssBaseline,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Tooltip,
	TooltipProps,
	Typography,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

import AppBar from "@commonComponents/AppBar";
import Drawer from "@commonComponents/Drawer";
import ToolBarDiv from "@commonComponents/ToolBarDiv";
import UserBadge from "@commonComponents/UserBadge";
import Context, { UserContext } from "@infra/Context";
import menuStyles from "./NavMenu.module.scss";
import { useMenuItems } from "./menuItems";

const drawerWidth = 300;

export interface NavMenuProps {
	onLogout: React.MouseEventHandler;
	children: JSX.Element[] | JSX.Element | string;
}

const BiggerTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		// backgroundColor: theme.palette.common.white,
		// color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 12,
	},
}));

export default function NavMenu(props: NavMenuProps) {
	const menuItems = useMenuItems();
	const context = React.useContext(Context) as UserContext;
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const childrenItems = () => {
		const accessMode = context.userInfo.accessMode;

		return menuItems
			.filter((item) => (item.accessMode ^ accessMode) + accessMode === item.accessMode)
			.map((item, index) => {
				if (!item) return null;

				return (
					<Link
						to={item.routePath}
						key={index}
						underline="none"
						component={RouterLink}
						color="CaptionText"
					>
						<ListItem button key={index}>
							<BiggerTooltip
								title={
									<React.Fragment>
										<div>{item.titleText}</div>
									</React.Fragment>
								}
							>
								<Box
									sx={(theme) => ({
										borderRadius: 2,
										backgroundColor: item.iconColor,
										color: theme.palette.common.white,
										padding: 1,
										mr: 2,
										ml: -0.5,
										width: 40,
										height: 40,
									})}
								>
									<ListItemIcon>{item.iconElement}</ListItemIcon>
								</Box>
							</BiggerTooltip>
							<ListItemText primary={item.titleText} />
						</ListItem>
					</Link>
				);
			});
	};

	const sxRoot: SxProps<Theme> = (theme) => ({
		display: "flex",
		alignItems: "center",
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		height: "100vh",
	});

	return (
		<Box sx={sxRoot}>
			<CssBaseline />

			<AppBar open={open} delta={drawerWidth}>
				<Toolbar
					sx={[
						{ background: "#279d00" },
						{
							background:
								"-moz-linear-gradient(top, #279d00 0%, #279d00 70%, #066A13 100%)",
						},
						{
							background:
								"-webkit-linear-gradient(top, #279d00 0%, #279d00 70%, #066A13 100%)",
						},
						{
							background:
								"linear-gradient(to bottom, #279d00 0%, #279d00 70%, #066A13 100%)",
						},
					]}
				>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							"marginRight": 1,
							...(open && { display: "none" }),
							"@media (max-width: 450px)": {
								display: "none",
							},
						}}
					>
						<MenuIcon />
					</IconButton>

					{/* Поле для отображения объекта управления - Наименование СНТ или Аккаунта */}
					<Typography
						variant="h6"
						noWrap
						sx={{
							"flexGrow": 1,
							"display": open ? "none" : "block",
							"@media (max-width: 450px)": {
								display: "none",
							},
						}}
					>
						{!open && context.userInfo.activeHoa?.Name}
					</Typography>

					{/* Логотип системы */}
					<Typography
						variant="h6"
						noWrap
						sx={{
							"flexGrow": 1,
							"textAlign": open ? "left" : "center",
							"@media (max-width: 450px)": {
								textAlign: "left",
							},
						}}
					>
						Ожигово
					</Typography>

					{/* Имя пользователя со встроенным меню */}
					<UserBadge
						onExit={props.onLogout}
						userTitle={
							context.userInfo.Name ? context.userInfo.Name.SurnameInitials : ""
						}
						className={menuStyles.username}
						style={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							color: "#fff",
						}}
					/>
				</Toolbar>
			</AppBar>

			<Drawer
				open={open}
				width={drawerWidth}
				className={open ? menuStyles["app-drawer__opened"] : menuStyles["app-drawer"]}
			>
				<ToolBarDiv>
					<Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
						{open && context.userInfo.activeHoa?.Name}
					</Typography>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</ToolBarDiv>
				<Divider />
				<List>{childrenItems()}</List>
				<Divider />
			</Drawer>

			<Box className={menuStyles["main-content-wrapper"]}>{props.children}</Box>
		</Box>
	);
}
